import moment from 'moment';
import { Subscription } from 'rxjs';
import { Page } from 'src/app/Connection';
import { PaginateConfig } from 'src/app/Models/PaginateConfig';
import { AllocationProgressLV } from 'src/app/ServiceModels/AllocationProgressLV';
import { MsalBasedAuthorizationService } from 'src/app/Services/msal-based-authorization.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ReconciliationAllocationService } from 'src/app/Services/reconciliation-allocation.service';

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { RECONCILATIONS_CONFIG } from '../reconciliations-config';

@Component({
  selector: 'app-reconciliations-overview-component',
  templateUrl: './reconciliations-overview.component.html',
  styleUrls: ['./reconciliations-overview.component.scss'],
})
export class ReconciliationsOverviewComponent implements PaginateConfig, OnInit, OnDestroy {
  @Output() openDetailEvent = new EventEmitter<string>();
  @Output() noDataEvent = new EventEmitter<boolean>();

  subscriptions: Subscription[] = [];

  data: Page<AllocationProgressLV>;

  pageSizes: number[] = [10, 25, 100];
  currentPage = 1;
  itemsPerPage: number = RECONCILATIONS_CONFIG.pagesizes.overview;
  totalItems = 0;

  searchString = '';
  isCorrectedFilter = false; // Default value of false, thus only showing not corrected reconciliations at first opening screen
  isEditingExternalReference: boolean[] = [];
  formDataGroup: UntypedFormGroup;

  maxLengthExternalReference = RECONCILATIONS_CONFIG.definitions.allocationProgress.externalReferenceMaxLength;

  isLoading: boolean;

  paginateConfig: PaginateConfig = {
    id: 'paginationOverview',
    itemsPerPage: this.itemsPerPage,
    currentPage: this.currentPage,
    totalItems: this.totalItems,
  } as PaginateConfig;

  constructor(
    private formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly reconciliationAllocationService: ReconciliationAllocationService,
    private readonly notificationService: NotificationService,
    private readonly msalBasedAuthorizationService: MsalBasedAuthorizationService,
  ) {
    this.formDataGroup = this.formBuilder.group({
      rows: this.formBuilder.array([]),
    });

    this.initFormArray(100);
  }

  get isEditingAllowed() {
    return this.msalBasedAuthorizationService.hasReconciliationRole(RECONCILATIONS_CONFIG.roles.writeRole);
  }

  get isEditingAnyExternalReference(): boolean {
    return this.isEditingAllowed && this.isEditingExternalReference.some((x) => x === true);
  }

  get maxPage() {
    return this.data ? Math.ceil(this.data.totalCount / this.itemsPerPage) : 1;
  }

  get dataCountIsLargerThenMinimumPageSize(): boolean {
    const minimumPageSize = this.pageSizes && this.pageSizes.length ? this.pageSizes[0] : 0;
    return this.data.totalCount > minimumPageSize;
  }

  get getRows(): FormArray {
    return this.formDataGroup.controls.rows as FormArray;
  }

  get determineQueryPath(): string {
    let queryPath = '?';

    if (this.isCorrectedFilter !== undefined) {
      if (queryPath !== '?') {
        queryPath += '&';
      }

      queryPath += 'isCorrected=' + this.isCorrectedFilter;
    }

    if (this.searchString !== undefined) {
      if (queryPath !== '?') {
        queryPath += '&';
      }

      queryPath += 'search=' + this.searchString;
    }

    return queryPath;
  }

  getFormExternalReference(index: number): string {
    return this.getRows.value[index].externalReference;
  }

  setFormExternalReference(index: number, value: string) {
    const rows = this.getRows;

    if (rows.value[index].externalReference !== value) {
      const array = rows.value;
      array[index].externalReference = value;
      rows.setValue(array);
    }
  }

  initFormArray(rowCount: number): void {
    const rows = this.getRows;
    rows.clear();

    for (let i = 0; i < rowCount; i++) {
      rows.push(this.createFormData(null));
    }
  }

  getPageData(itemsPerPage: number, pageNumber: number): AllocationProgressLV[] {
    if (this.data?.data) {
      const currentIndex = itemsPerPage * (pageNumber - 1);
      const endIndex = currentIndex + itemsPerPage;
      return this.data.data.slice(currentIndex, endIndex);
    }

    return [];
  }

  fillPageFormArray(data: any, itemsPerPage: number, pageNumber: number): void {
    const rows = this.getRows;
    rows.clear();

    if (data) {
      this.getPageData(itemsPerPage, pageNumber).forEach((item: AllocationProgressLV) => {
        rows.push(this.createFormData(item));
      });
    }
  }

  createFormData(data: any) {
    data = data || { externalReference: null };

    return this.formBuilder.group({
      externalReference: data.externalReference,
    });
  }

  ngOnInit() {
    this.initialiseData();
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach((s) => s.unsubscribe());
    }
  }

  initialiseData() {
    this.checkRouterParams();
    this.fetchData();
  }

  checkRouterParams() {
    this.subscriptions.push(
      this.route.queryParamMap.subscribe((paramsMap: any) => {
        if (paramsMap.keys.includes('open')) {
          this.isCorrectedFilter = paramsMap.params.open;
        }
        if (paramsMap.keys.includes('search')) {
          this.searchString = paramsMap.params.search;
        }
      }),
    );
  }

  checkPage() {
    if (this.route.snapshot.queryParams.page) {
      this.goToPage(Number.parseInt(this.route.snapshot.queryParams.page, 10));
    } else {
      this.initialisePage(1);
    }
  }

  checkPageSize() {
    if (this.route.snapshot.queryParams.size) {
      this.setPageSize(this.route.snapshot.queryParams.size);
    } else {
      const pageSize = localStorage.getItem('reconciliations-overview-size');
      if (pageSize) {
        this.setPageSize(pageSize);
      }
    }
  }

  openDetail(id: string) {
    this.openDetailEvent.emit(id);
  }

  changeSelectionIsCorrectedFilter(event: { value: boolean }) {
    this.isCorrectedFilter = event.value;
    const queryPath = this.determineQueryPath;
    history.pushState(null, '', location.pathname + queryPath); // TODO : DW: incorporate page and tab query
    this.fetchData();
  }

  searchEventlistener(event: { shortcut: boolean; searchString: string }) {
    if (event.shortcut === true) {
      // shortcut option to quickly navigate using ctrl click
      //this.router.navigate(['/??', event.searchString]);
    } else {
      this.onUpdateSearch(event.searchString);
    }
  }

  onUpdateSearch(searchString: string) {
    this.searchString = searchString;
    const queryPath = this.determineQueryPath;
    history.pushState(null, '', location.pathname + queryPath); // TODO : DW: incorporate page and tab query
    this.fetchData();

    this.goToPage(1);
  }

  fetchData() {
    if (!this.isLoading) {
      this.isLoading = true;
      this.getDataFromApi();
    }
  }

  getDataFromApi() {
    this.subscriptions.push(
      this.reconciliationAllocationService.getAllValidAllocationProgressesForVVV().subscribe((results) => {
        try {
          if (results) {
            const pagedData = Page.readArrayObject<AllocationProgressLV>(results, 'AllocationProgressLV');
            this.data = pagedData;

            this.totalItems = this.data.totalCount;
            this.paginateConfig.totalItems = this.data.totalCount;
          }

          if (results && this.data.totalCount > 0) {
            this.checkPageSize();
            this.checkPage();
            this.initFormData(this.itemsPerPage);
          } else {
            this.noDataEvent.emit(true);
          }
        } catch (err: any) {
          throw new Error(err);
        } finally {
          this.isLoading = false;
        }
      }),
    );
  }

  pageInUrlIsDifferent(pageNumber: number): boolean {
    const url = new URL(location.href);
    const searchParams = url.searchParams;
    if (searchParams.has('page')) {
      return url.searchParams.get('page') !== pageNumber.toString();
    }
    return true;
  }

  goToPage(pageNumber: number) {
    // Check if page is set to an incorrect value, reset to 1
    if (pageNumber < 0 || pageNumber > this.maxPage) {
      pageNumber = 1;
    }

    const pageInUrlIsDifferent = this.pageInUrlIsDifferent(pageNumber);

    if (pageInUrlIsDifferent) {
      const url = new URL(location.href);
      const searchParams = url.searchParams;
      if (searchParams.has('page')) {
        url.searchParams.set('page', pageNumber.toString());
      } else {
        url.searchParams.append('page', pageNumber.toString());
      }

      history.pushState(null, null, url);
    }

    this.initialisePage(pageNumber);
  }

  initialisePage(pageNumber: number) {
    // Check if page is set to an incorrect value, reset to 1
    if (pageNumber < 0 || pageNumber > this.maxPage) {
      pageNumber = 1;
    }

    if (this.currentPage !== pageNumber) {
      this.cancelAllExternalReferences();
    }

    this.currentPage = pageNumber;
    this.paginateConfig.currentPage = pageNumber;

    // this.fetchData(); // TODO: DW: Do this when using Paging in API
  }

  pageSizeInUrlIsDifferent(pageSize: number): boolean {
    const url = new URL(location.href);
    const searchParams = url.searchParams;
    if (searchParams.has('size')) {
      return url.searchParams.get('size') !== pageSize.toString();
    }
    return true;
  }

  setPageSize(pageSize: string) {
    let pageSizeValue = Number(pageSize);
    // Check if page is set to an incorrect value, reset to 1
    if (pageSizeValue < 0 || !this.pageSizes.includes(pageSizeValue)) {
      pageSizeValue = RECONCILATIONS_CONFIG.pagesizes.overview;
    }

    const pageSizeInUrlIsDifferent = this.pageSizeInUrlIsDifferent(pageSizeValue);

    if (pageSizeInUrlIsDifferent) {
      const url = new URL(location.href);
      const searchParams = url.searchParams;
      if (searchParams.has('size')) {
        url.searchParams.set('size', pageSize.toString());
      } else {
        url.searchParams.append('size', pageSize.toString());
      }

      history.pushState(null, null, url);
    }

    this.initFormData(pageSizeValue);

    this.itemsPerPage = pageSizeValue;
    this.paginateConfig.itemsPerPage = pageSizeValue;
    localStorage.setItem('reconciliations-overview-size', pageSizeValue.toString());
  }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    const params: any = {};

    if (searchTitle) {
      params.title = searchTitle;
    }

    if (page) {
      params.page = page - 1;
    }

    if (pageSize) {
      params.size = pageSize;
    }

    return params;
  }

  onPageSizeChange(pageSize: string): void {
    this.setPageSize(pageSize);
    this.goToPage(1);
  }

  initFormData(itemsPerPage: number) {
    const maxRowCount = this.data.totalCount > itemsPerPage ? itemsPerPage : this.data.totalCount;

    // Init editing state for each visible row
    Array(maxRowCount).fill(this.isEditingExternalReference);
    this.isEditingExternalReference = this.isEditingExternalReference.map(() => false);

    this.fillPageFormData(itemsPerPage, this.currentPage);
  }

  fillPageFormData(itemsPerPage: number, pageNumber: number) {
    if (this.data?.data) {
      this.fillPageFormArray(this.data.data, itemsPerPage, pageNumber);
    }
  }

  editExternalReference(index: number, dataItem: AllocationProgressLV) {
    this.setFormExternalReference(index, dataItem.externalReference);

    if (this.isEditingAllowed && this.isEditingExternalReference[index] !== true) {
      this.isEditingExternalReference[index] = true;
    }
  }

  cancelEditExternalReference(index: number, dataItem: AllocationProgressLV) {
    if (this.isEditingExternalReference[index] === true) {
      this.isEditingExternalReference[index] = false;
    }

    if (this.isEditingExternalReference[index] === false) {
      this.setFormExternalReference(index, dataItem.externalReference);
    }
  }

  saveExternalReference(index: number, dataItem: AllocationProgressLV, showMessage: boolean = true) {
    if (this.isEditingExternalReference[index] === true) {
      const externalReference = this.getFormExternalReference(index) ?? '';

      this.subscriptions.push(
        this.reconciliationAllocationService
          .updateAllocationProgressExternalReferenceById(dataItem.id, externalReference)
          .subscribe((result: AllocationProgressLV) => {
            dataItem = result;
            if (showMessage) {
              this.notificationService.showSuccess('VVV data opgeslagen.');
            }
          }),
      );

      dataItem.externalReference = externalReference.trim();

      this.isEditingExternalReference[index] = false;
    }
  }

  saveAllExternalReferences() {
    this.getPageData(this.itemsPerPage, this.currentPage).forEach((data, index) => {
      this.saveExternalReference(index, data, false);
      setTimeout(() => {
        this.notificationService.showSuccess('Alle VVV data opgeslagen.');
      }, 2000);
    });
  }

  cancelAllExternalReferences() {
    this.getPageData(this.itemsPerPage, this.currentPage).forEach((data, index) => this.cancelEditExternalReference(index, data));
  }

  fillWithSpaces(text: string, numberOfSpaces: number) {
    return (text?.trim() ?? '') === '' ? ' '.repeat(numberOfSpaces) : text;
  }
}
