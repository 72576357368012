import { BehaviorSubject, Subscription } from 'rxjs';
import { TruncatePipe } from 'src/app/Pipes/truncate.pipe';
import { ReconciliationAllocationService } from 'src/app/Services/reconciliation-allocation.service';

import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reconciliations-dashboard-component',
  templateUrl: './reconciliations-dashboard.component.html',
  styleUrls: ['./reconciliations-dashboard.component.scss'],
})
export class ReconciliationsDashboardComponent implements OnInit, OnDestroy {
  tab = 0;

  subscriptions: Subscription[] = [];
  initializedList: number[] = [];

  detailId$ = new BehaviorSubject<string>(undefined);
  connectionEan$ = new BehaviorSubject<string>(undefined);

  detailsTabTitle$ = new BehaviorSubject<string>('');
  volumesTabTitle$ = new BehaviorSubject<string>('');

  constructor(
    private readonly route: ActivatedRoute,
    private readonly reconciliationAllocationService: ReconciliationAllocationService,
    private readonly truncatePipe: TruncatePipe,
    private readonly datePipe: DatePipe,
  ) {}

  ngOnInit() {
    this.tab = 0;

    if (this.route.snapshot.queryParams.tab != null) {
      this.tab = Number.parseInt(this.route.snapshot.queryParams.tab, 10);
    }

    this.tabWasInitialized(this.tab);

    const detailIdFromStorage = localStorage.getItem('reconciliations-detailId');
    this.detailId$.next(detailIdFromStorage);

    const connectionEanFromStorage = localStorage.getItem('reconciliations-connectionEan');
    this.connectionEan$.next(connectionEanFromStorage);

    this.initialiseData();

    window.onpopstate = (event: { state: any }) => {
      if (event && event.state) {
        location.reload();
      } else {
        window.location = window.location;
      }
    };
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach((s) => s.unsubscribe());
    }
  }

  initialiseData() {
    this.subscriptions.push(
      this.detailId$.subscribe((detailId) => {
        if (detailId !== undefined && detailId !== null && detailId !== '') {
          this.subscriptions.push(
            this.reconciliationAllocationService.getAllocationProgressById(detailId).subscribe((data) => {
              if (detailId && data && data.gridOperatorName && data.processedDate) {
                const truncatedGridOperatorName = this.truncatePipe.transform(data.gridOperatorName, [20, '...']);
                const formattedProcessedDate = this.datePipe.transform(data.processedDate, 'MMM yyyy');

                this.detailsTabTitle$.next('(' + truncatedGridOperatorName + ' - ' + formattedProcessedDate + ')');
              } else if (detailId && data) {
                this.detailsTabTitle$.next('(' + 'onbekend' + ')');
                this.volumesTabTitle$.next('');
              } else {
                this.detailsTabTitle$.next('');
                this.volumesTabTitle$.next('');
              }
            }),
          );
        } else {
          this.detailsTabTitle$.next('');
          this.volumesTabTitle$.next('');
        }
      }),
      this.connectionEan$.subscribe((connectionEan) => {
        const detailIdStored = localStorage.getItem('reconciliations-detailId');

        if (detailIdStored && connectionEan) {
          this.volumesTabTitle$.next('(' + connectionEan + ')');
        } else {
          this.volumesTabTitle$.next('');
        }
      }),
    );
  }

  selectTab(tab: number) {
    if (this.tab !== tab) {
      this.tab = tab;
      this.tabWasInitialized(this.tab);
    }
  }

  tabWasInitialized(tabNumber: number) {
    if (!this.initializedList.includes(tabNumber)) {
      this.initializedList.push(tabNumber);
    }
  }

  isInitialized(i: number) {
    return this.initializedList.includes(i);
  }

  cleanStorage() {
    localStorage.removeItem('reconciliations-detailId');
    localStorage.removeItem('reconciliations-connectionEan');

    this.detailId$.next(undefined);
    this.connectionEan$.next(undefined);
  }

  noOverviewData(isEmpty: boolean) {
    if (isEmpty) {
      this.cleanStorage();
    }
  }

  openDetail(detailId: string) {
    if (detailId) {
      const detailIdStored = localStorage.getItem('reconciliations-detailId');

      if (detailIdStored !== detailId) {
        localStorage.setItem('reconciliations-detailId', detailId);
        localStorage.removeItem('reconciliations-connectionEan');

        this.detailId$.next(detailId);
        this.connectionEan$.next(undefined);
      }

      this.openTab(1);
    } else {
      localStorage.removeItem('reconciliations-detailId');
    }
  }

  openVolume(connectionEan: string) {
    if (connectionEan) {
      const connectionEanStored = localStorage.getItem('reconciliations-detailId');

      if (connectionEanStored !== connectionEan) {
        localStorage.setItem('reconciliations-connectionEan', connectionEan);

        this.connectionEan$.next(connectionEan);
      }

      this.openTab(2);
    } else {
      localStorage.removeItem('reconciliations-connectionEan');
    }
  }

  tabInUrlIsDifferent(index: number): boolean {
    const url = new URL(location.href);
    const searchParams = url.searchParams;
    if (searchParams.has('tab')) {
      return url.searchParams.get('tab') !== index.toString();
    }
    return true;
  }

  openTab(index: number) {
    const tabInUrlIsDifferent = this.tabInUrlIsDifferent(index);

    if (tabInUrlIsDifferent) {
      const url = new URL(location.href);
      const searchParams = url.searchParams;
      if (searchParams.has('tab')) {
        url.searchParams.set('tab', index.toString());
      } else {
        url.searchParams.append('tab', index.toString());
      }
      if (searchParams.has('page')) {
        url.searchParams.set('page', '1');
      } else {
        url.searchParams.append('page', '1');
      }
      if (searchParams.has('size')) {
        url.searchParams.delete('size');
      }

      history.pushState(null, null, url);
    }

    this.selectTab(index);
  }

  isNotSet(data: any) {
    return data === undefined || data === null || data?.trim() === '';
  }
}
