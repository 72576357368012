import { catchError, map, Observable, of, throwError } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

import { RECONCILATIONS_CONFIG } from '../Dashboards/Reconciliations/reconciliations-config';
import { AllocationProgressLV } from '../ServiceModels/AllocationProgressLV';
import { AllocationProgressLVDetailSeriesVVV } from '../ServiceModels/AllocationProgressLVDetailSeriesVVV';
import { ProcessedConnectionByVVV } from '../ServiceModels/ProcessedConnectionByVVV';
import { ErrorService } from '../Services/error.service';

export const RECONCILIATION_API_URL = () => environment.getReconciliationApiUrl();

const ALL_PROCESSED_CONNECTIONS_BY_VVV_BY_ALLOCATION_PROGRESS_ID_URL =
  RECONCILIATION_API_URL() + RECONCILATIONS_CONFIG.baseApiUrls.processedConnectionsByVVV.allocationProgresses.item.base;
const ALL_VALID_PROCESSED_CONNECTIONS_BY_VVV_BY_VALID_ALLOCATION_PROGRESS_ID_URL =
  RECONCILIATION_API_URL() + RECONCILATIONS_CONFIG.baseApiUrls.processedConnectionsByVVV.allocationProgresses.item.valid;
const SINGLE_PROCESSED_CONNECTION_BY_VVV_URL = RECONCILIATION_API_URL() + RECONCILATIONS_CONFIG.baseApiUrls.processedConnectionsByVVV.item;
const SINGLE_PROCESSED_CONNECTION_BY_VVV_EXTERNAL_REFERENCE_BY_ALLOCATION_PROGRESS_CONNECTION_EAN_URL =
  RECONCILIATION_API_URL() + RECONCILATIONS_CONFIG.baseApiUrls.processedConnectionsByVVV.allocationProgresses.item.connections.item.externalReference;
const SINGLE_PROCESSED_CONNECTION_BY_VVV_IS_PROCESSED_BY_ALLOCATION_PROGRESS_CONNECTION_EAN_URL =
  RECONCILIATION_API_URL() + RECONCILATIONS_CONFIG.baseApiUrls.processedConnectionsByVVV.allocationProgresses.item.connections.item.isCorrected;

const ALL_ALLOCATION_PROGRESSES_URL = RECONCILIATION_API_URL() + RECONCILATIONS_CONFIG.baseApiUrls.allocationProgresses.all;
const ALL_VALID_ALLOCATION_PROGRESSES_URL = RECONCILIATION_API_URL() + RECONCILATIONS_CONFIG.baseApiUrls.allocationProgresses.allValid;
const SINGLE_ALLOCATION_PROGRESS_URL = RECONCILIATION_API_URL() + RECONCILATIONS_CONFIG.baseApiUrls.allocationProgresses.item.base;
const SINGLE_ALLOCATION_PROGRESS_EXTERNAL_REFERENCE_URL =
  RECONCILIATION_API_URL() + RECONCILATIONS_CONFIG.baseApiUrls.allocationProgresses.item.externalReference;

const SINGLE_ALLOCATION_PROGRESS_DETAIL_SERIES_VVV_BY_ALLOCATION_PROGRESS_ID_URL =
  RECONCILIATION_API_URL() + RECONCILATIONS_CONFIG.baseApiUrls.allocationProgressLVDetailSeriesVVV.allocationProgresses.item.base;

@Injectable({
  providedIn: 'root',
})
export class ReconciliationAllocationService {
  headers: HttpHeaders;
  options: any;

  constructor(private readonly http: HttpClient, private readonly errorService: ErrorService) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json', accept: 'q=0.8;application/json;q=0.9' });
    this.options = { headers: this.headers };
  }

  getService(url: string): Observable<any> {
    return this.http.get<Response>(url, this.options).pipe(catchError((e) => this.handleServiceError(e)));
  }

  getSearchService(url: string, params: { offset: string; limit: string; search: string; isCorrected: string }) {
    this.options.params = params;
    return this.http.get<Response>(url, this.options).pipe(catchError((e) => this.handleServiceError(e)));
  }

  updateService(url: string): Observable<any> {
    return this.http.put<Response>(url, this.options).pipe(catchError((e) => this.handleServiceError(e)));
  }

  updateServiceWithParam(url: string, param: any): Observable<any> {
    const body = JSON.stringify(param);
    return this.http.put<Response>(url, body, this.options).pipe(catchError((e) => this.handleServiceError(e)));
  }

  public updateAllocationProgressExternalReferenceById(id: string, externalReference: string): Observable<AllocationProgressLV> {
    const url = SINGLE_ALLOCATION_PROGRESS_EXTERNAL_REFERENCE_URL.replace('{0}', id.toString());
    return this.updateServiceWithParam(url, externalReference);
  }

  public updateProcessedConnectionsByVVVExternalReferenceByAllocationProgressConnectionEan(
    allocationProgressId: string,
    connectionEan: string,
    externalReference: string,
  ): Observable<ProcessedConnectionByVVV> {
    const url = SINGLE_PROCESSED_CONNECTION_BY_VVV_EXTERNAL_REFERENCE_BY_ALLOCATION_PROGRESS_CONNECTION_EAN_URL.replace(
      '{0}',
      allocationProgressId.toString(),
    ).replace('{1}', connectionEan.toString());
    return this.updateServiceWithParam(url, externalReference);
  }

  public updateProcessedConnectionsByVVVIsCorrectedByAllocationProgressConnectionEan(
    allocationProgressId: string,
    connectionEan: string,
    isCorrected: boolean,
  ): Observable<ProcessedConnectionByVVV> {
    const url = SINGLE_PROCESSED_CONNECTION_BY_VVV_IS_PROCESSED_BY_ALLOCATION_PROGRESS_CONNECTION_EAN_URL.replace(
      '{0}',
      allocationProgressId.toString(),
    )
      .replace('{1}', connectionEan.toString())
      .replace('{2}', isCorrected.toString());
    return this.updateService(url);
  }

  getAllProcessedConnectionsByVVVByAllocationProgressId(allocationProgressId: string): Observable<ProcessedConnectionByVVV[]> {
    const url = ALL_PROCESSED_CONNECTIONS_BY_VVV_BY_ALLOCATION_PROGRESS_ID_URL.replace('{0}', allocationProgressId.toString());
    const result = this.getService(url).pipe(
      catchError(() => of([])),
      map((response) => {
        //Apparantly we chose not to make it an error is an empty array 'object' is returned.
        //But to create an errorlogging, we check for the empty object and return an error if empty === true.
        if (Object.keys(response).length === 0) {
          this.errorService.addSilentError('Geen detail VVVs gevonden.');
          return response;
        }

        return (response as any[]).map((responseItem: ProcessedConnectionByVVV) => ProcessedConnectionByVVV.readObject(responseItem));
      }),
      catchError((err) => err),
    );

    return result as Observable<ProcessedConnectionByVVV[]>;
  }

  getAllValidProcessedConnectionsByVVVByValidAllocationProgressId(allocationProgressId: string): Observable<ProcessedConnectionByVVV[]> {
    const url = ALL_VALID_PROCESSED_CONNECTIONS_BY_VVV_BY_VALID_ALLOCATION_PROGRESS_ID_URL.replace('{0}', allocationProgressId.toString());
    const result = this.getService(url).pipe(
      catchError(() => of([])),
      map((response) => {
        //Apparantly we chose not to make it an error is an empty array 'object' is returned.
        //But to create an errorlogging, we check for the empty object and return an error if empty === true.
        if (Object.keys(response).length === 0) {
          this.errorService.addSilentError('Geen detail VVVs gevonden.');
          return response;
        }

        return (response as any[]).map((responseItem: ProcessedConnectionByVVV) => ProcessedConnectionByVVV.readObject(responseItem));
      }),
      catchError((err) => err),
    );

    return result as Observable<ProcessedConnectionByVVV[]>;
  }

  getAllAllocationProgressesForVVV(): Observable<AllocationProgressLV[]> {
    const url = ALL_ALLOCATION_PROGRESSES_URL;
    const result = this.getService(url).pipe(
      catchError(() => of([])),
      map((response) => {
        //Apparantly we chose not to make it an error if an empty array 'object' is returned.
        //But to create an errorlogging, we check for the empty object and return an error if empty === true.
        if (Object.keys(response).length === 0) {
          this.errorService.addSilentError(`Geen VVVs gevonden.`);
          return response;
        } else {
          return (response as any[]).map((responseItem: AllocationProgressLV) => AllocationProgressLV.readObject(responseItem));
        }
      }),
      catchError((err) => err),
    );

    return result as Observable<AllocationProgressLV[]>;
  }

  getAllValidAllocationProgressesForVVV(): Observable<AllocationProgressLV[]> {
    const url = ALL_VALID_ALLOCATION_PROGRESSES_URL;
    const result = this.getService(url).pipe(
      catchError(() => of([])),
      map((response) => {
        //Apparantly we chose not to make it an error if an empty array 'object' is returned.
        //But to create an errorlogging, we check for the empty object and return an error if empty === true.
        if (Object.keys(response).length === 0) {
          this.errorService.addSilentError(`Geen VVVs gevonden.`);
          return response;
        } else {
          return (response as any[]).map((responseItem: AllocationProgressLV) => AllocationProgressLV.readObject(responseItem));
        }
      }),
      catchError((err) => err),
    );

    return result as Observable<AllocationProgressLV[]>;
  }

  getProcessedConnectionVVVById(id: string): Observable<ProcessedConnectionByVVV> {
    const url = SINGLE_PROCESSED_CONNECTION_BY_VVV_URL.replace('{0}', id.toString());

    const result = this.getService(url).pipe(
      catchError(() => of(undefined)),
      map((responseItem) => {
        //Apparantly we chose not to make it an error is an empty 'object' is returned.
        //But to create an errorlogging, we check for the empty object and return an error if empty === true.
        if (responseItem === undefined) {
          this.errorService.addSilentError('VVV detail item niet gevonden.');
          return responseItem;
        } else {
          return ProcessedConnectionByVVV.readObject(responseItem);
        }
      }),
      catchError((err) => err),
    );

    return result as Observable<ProcessedConnectionByVVV>;
  }

  getAllocationProgressById(id: string): Observable<AllocationProgressLV> {
    const url = SINGLE_ALLOCATION_PROGRESS_URL.replace('{0}', id.toString());

    const result = this.getService(url).pipe(
      catchError(() => of(undefined)),
      map((responseItem: any) => {
        //Apparantly we chose not to make it an error is an empty 'object' is returned.
        //But to create an errorlogging, we check for the empty object and return an error if empty === true.
        if (responseItem === undefined) {
          this.errorService.addSilentError('VVV niet gevonden.');
          return responseItem;
        } else {
          return AllocationProgressLV.readObject(responseItem);
        }
      }),
      catchError((err) => err),
    );

    return result as Observable<AllocationProgressLV>;
  }

  getAllocationProgressDetailSeriesVVVByAllocationProgressId(allocationProgressId: string): Observable<AllocationProgressLVDetailSeriesVVV> {
    const url = SINGLE_ALLOCATION_PROGRESS_DETAIL_SERIES_VVV_BY_ALLOCATION_PROGRESS_ID_URL.replace('{0}', allocationProgressId.toString());

    const result = this.getService(url).pipe(
      catchError(() => of(undefined)),
      map((responseItem: any) => {
        //Apparantly we chose not to make it an error is an empty 'object' is returned.
        //But to create an errorlogging, we check for the empty object and return an error if empty === true.
        if (responseItem === undefined) {
          this.errorService.addSilentError('VVV json data niet gevonden.');
          return responseItem;
        } else {
          return AllocationProgressLVDetailSeriesVVV.readObject(responseItem);
        }
      }),
      catchError((err) => err),
    );

    return result as Observable<AllocationProgressLVDetailSeriesVVV>;
  }

  private handleServiceError(error: any): Observable<never> {
    const statusMessage = error.status ? `${error.status}${error.statusText ? ` - ${error.statusText}` : ''}` : '';

    const generalErrors = error?.error?.errors?.generalErrors ?? error?.error?.message ?? error?.message;

    const errMsg = generalErrors || statusMessage ? `${JSON.stringify(generalErrors)}${statusMessage ? ` : ${statusMessage}` : ''}` : 'Server error';

    this.errorService.addSilentError(errMsg);

    return throwError(() => new Error(errMsg));
  }
}
