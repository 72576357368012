import { Subscription } from 'rxjs';
import { Page } from 'src/app/Connection';
import { PaginateConfig } from 'src/app/Models/PaginateConfig';
import { AllocationProgressLV } from 'src/app/ServiceModels/AllocationProgressLV';
import { ProcessedConnectionByVVV } from 'src/app/ServiceModels/ProcessedConnectionByVVV';
import { ReconciliationAllocationService } from 'src/app/Services/reconciliation-allocation.service';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { RECONCILATIONS_CONFIG } from '../reconciliations-config';

@Component({
  selector: 'app-reconciliations-details-component',
  templateUrl: './reconciliations-details.component.html',
  styleUrls: ['./reconciliations-details.component.scss'],
})
export class ReconciliationsDetailsComponent implements PaginateConfig, OnInit, OnDestroy {
  @Input() detailId: string;
  @Output() openVolumeEvent = new EventEmitter<string>();

  detailIdFromRoute: string;
  subscriptions: Subscription[] = [];

  parentData: AllocationProgressLV;
  data: Page<ProcessedConnectionByVVV>;

  pageSizes: number[] = [10, 25, 100];
  currentPage = 1;
  itemsPerPage: number = RECONCILATIONS_CONFIG.pagesizes.details;
  totalItems = 0;

  searchString = '';

  isLoadingData = false;
  isLoadingParentData = false;

  paginateConfig: PaginateConfig = {
    id: 'paginationDetails',
    itemsPerPage: this.itemsPerPage,
    currentPage: this.currentPage,
    totalItems: this.totalItems,
  } as PaginateConfig;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly reconciliationAllocationService: ReconciliationAllocationService,
  ) {}

  get isLoading(): boolean {
    return this.isLoadingData || this.isLoadingParentData;
  }

  get maxPage() {
    return this.data ? Math.ceil(this.data.totalCount / this.itemsPerPage) : 1;
  }

  get dataCountIsLargerThenMinimumPageSize(): boolean {
    const minimumPageSize = this.pageSizes && this.pageSizes.length ? this.pageSizes[0] : 0;
    return this.data.totalCount > minimumPageSize;
  }

  get determineQueryPath(): string {
    let queryPath = '?';

    if (this.searchString !== undefined) {
      if (queryPath !== '?') {
        queryPath += '&';
      }

      queryPath += 'search=' + this.searchString;
    }

    return queryPath;
  }

  ngOnInit() {
    this.detailIdFromRoute = this.route.snapshot.paramMap.get('id');

    this.initialiseData();
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach((s) => s.unsubscribe());
    }
  }

  initialiseData() {
    this.checkRouterParams();
    this.fetchData();
  }

  checkRouterParams() {
    this.subscriptions.push(
      this.route.queryParamMap.subscribe((paramsMap: any) => {
        if (paramsMap.keys.includes('search')) {
          this.searchString = paramsMap.params.search;
        }
      }),
    );
  }

  checkPage() {
    if (this.route.snapshot.queryParams.page) {
      this.goToPage(Number.parseInt(this.route.snapshot.queryParams.page, 10));
    } else {
      this.initialisePage(1);
    }
  }

  checkPageSize() {
    if (this.route.snapshot.queryParams.size) {
      this.setPageSize(this.route.snapshot.queryParams.size);
    } else {
      const pageSize = localStorage.getItem('reconciliations-details-size');
      if (pageSize) {
        this.setPageSize(pageSize);
      }
    }
  }

  goToConnection(connectionEan: string) {
    const url: any = this.router.createUrlTree(['/connection', connectionEan]);
    window.open(url, '_blank');
  }

  openVolume(id: string) {
    this.openVolumeEvent.emit(id);
  }

  searchEventlistener(event: { shortcut: boolean; searchString: string }) {
    if (event.shortcut === true) {
      // shortcut option to quickly navigate using ctrl click
      //this.router.navigate(['/??', event.searchString]);
    } else {
      this.onUpdateSearch(event.searchString);
    }
  }

  onUpdateSearch(searchString: string) {
    this.searchString = searchString;
    const queryPath = this.determineQueryPath;
    history.pushState(null, '', location.pathname + queryPath); // TODO : DW: incorporate page and tab query
    this.fetchData();

    this.goToPage(1);
  }

  determinedDetailId(): string {
    if (this.detailId === undefined) {
      this.detailId = localStorage.getItem('reconciliations-detailId');
    }
    return this.detailId ?? this.detailIdFromRoute;
  }

  fetchData() {
    if (!this.isLoadingData && !this.isLoadingParentData) {
      this.isLoadingData = true;
      this.isLoadingParentData = true;
      this.getDataFromApi();
    }
  }

  getDataFromApi() {
    const detailId = this.determinedDetailId();

    if (detailId !== undefined && detailId !== null && detailId !== '') {
      this.subscriptions.push(
        this.reconciliationAllocationService.getAllocationProgressById(detailId).subscribe((result: AllocationProgressLV) => {
          this.parentData = result;
          this.isLoadingData = false;
        }),
        this.reconciliationAllocationService
          .getAllValidProcessedConnectionsByVVVByValidAllocationProgressId(detailId)
          .subscribe((results: ProcessedConnectionByVVV[]) => {
            try {
              if (results) {
                const pagedData = Page.readArrayObject<ProcessedConnectionByVVV>(results, 'ProcessedConnectionByVVV');
                pagedData.data = pagedData.data.sort((a, b) =>
                  b.consumptionEndDateTime.toString().localeCompare(a.consumptionEndDateTime.toString()),
                );
                this.data = pagedData;

                this.totalItems = this.data.totalCount;
                this.paginateConfig.totalItems = this.data.totalCount;
              }

              if (results && this.data.totalCount > 0) {
                this.checkPageSize();
                this.checkPage();
              }
            } catch (err: any) {
              throw new Error(err);
            } finally {
              this.isLoadingParentData = false;
            }
          }),
      );
    }
  }

  pageInUrlIsDifferent(pageNumber: number): boolean {
    const url = new URL(location.href);
    const searchParams = url.searchParams;
    if (searchParams.has('page')) {
      return url.searchParams.get('page') !== pageNumber.toString();
    }
    return true;
  }

  goToPage(pageNumber: number) {
    // Check if page is set to an incorrect value, reset to 1
    if (pageNumber < 0 || pageNumber > this.maxPage) {
      pageNumber = 1;
    }

    const pageInUrlIsDifferent = this.pageInUrlIsDifferent(pageNumber);

    if (pageInUrlIsDifferent) {
      const url = new URL(location.href);
      const searchParams = url.searchParams;
      if (searchParams.has('page')) {
        url.searchParams.set('page', pageNumber.toString());
      } else {
        url.searchParams.append('page', pageNumber.toString());
      }

      history.pushState(null, null, url);
    }

    this.initialisePage(pageNumber);
  }

  initialisePage(pageNumber: number) {
    // Check if page is set to an incorrect value, reset to 1
    if (pageNumber < 0 || pageNumber > this.maxPage) {
      pageNumber = 1;
    }

    this.currentPage = pageNumber;
    this.paginateConfig.currentPage = pageNumber;

    // this.fetchData(); // TODO: DW: Do this when using Paging in API
  }

  pageSizeInUrlIsDifferent(pageSize: number): boolean {
    const url = new URL(location.href);
    const searchParams = url.searchParams;
    if (searchParams.has('size')) {
      return url.searchParams.get('size') !== pageSize.toString();
    }
    return true;
  }

  setPageSize(pageSize: string) {
    let pageSizeValue = Number(pageSize);
    // Check if page is set to an incorrect value, reset to 1
    if (pageSizeValue < 0 || !this.pageSizes.includes(pageSizeValue)) {
      pageSizeValue = RECONCILATIONS_CONFIG.pagesizes.details;
    }

    const pageSizeInUrlIsDifferent = this.pageSizeInUrlIsDifferent(pageSizeValue);

    if (pageSizeInUrlIsDifferent) {
      const url = new URL(location.href);
      const searchParams = url.searchParams;
      if (searchParams.has('size')) {
        url.searchParams.set('size', pageSize.toString());
      } else {
        url.searchParams.append('size', pageSize.toString());
      }

      history.pushState(null, null, url);
    }

    this.itemsPerPage = pageSizeValue;
    this.paginateConfig.itemsPerPage = pageSizeValue;
    localStorage.setItem('reconciliations-details-size', pageSizeValue.toString());
  }

  onPageSizeChange(pageSize): void {
    this.setPageSize(pageSize);
    this.goToPage(1);
  }
}
