import moment from 'moment';
import { lastValueFrom, Subscription } from 'rxjs';
import { Page } from 'src/app/Connection';
import { BlobCreateHelper } from 'src/app/helpers/BlobCreateHelper';
import { PaginateConfig } from 'src/app/Models/PaginateConfig';
import { AllocationProgressLV } from 'src/app/ServiceModels/AllocationProgressLV';
import { ProcessedConnectionByVVV } from 'src/app/ServiceModels/ProcessedConnectionByVVV';
import { MsalBasedAuthorizationService } from 'src/app/Services/msal-based-authorization.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ReconciliationAllocationService } from 'src/app/Services/reconciliation-allocation.service';

import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { RECONCILATIONS_CONFIG } from '../reconciliations-config';

@Component({
  selector: 'app-reconciliations-volumes-component',
  templateUrl: './reconciliations-volumes.component.html',
  styleUrls: ['./reconciliations-volumes.component.scss'],
})
export class ReconciliationsVolumesComponent implements PaginateConfig, OnInit, OnDestroy {
  @Input() detailId: string;
  @Input() connectionEan: string;

  wasOrginallyCorrectedTrue: boolean = undefined;
  detailIdFromRoute: string;
  subscriptions: Subscription[] = [];

  parentData: AllocationProgressLV;
  data: Page<ProcessedConnectionByVVV>;

  pageSizes: number[] = [20, 50, 100];
  currentPage = 1;
  itemsPerPage: number = RECONCILATIONS_CONFIG.pagesizes.volumes;
  totalItems = 0;

  searchString = '';
  formDataGroup: UntypedFormGroup;

  maxLengthExternalReference = RECONCILATIONS_CONFIG.definitions.processedConnectionByVVV.externalReferenceMaxLength;

  isLoadingData = false;
  isLoadingParentData = false;

  paginateConfig: PaginateConfig = {
    id: 'paginationVolumes',
    itemsPerPage: this.itemsPerPage,
    currentPage: this.currentPage,
    totalItems: this.totalItems,
  } as PaginateConfig;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly reconciliationAllocationService: ReconciliationAllocationService,
    private readonly notificationService: NotificationService,
    private readonly msalBasedAuthorizationService: MsalBasedAuthorizationService,
  ) {
    this.formDataGroup = new UntypedFormGroup({
      externalReference: new UntypedFormControl(),
      isCorrected: new UntypedFormControl(),
    });
  }

  get isEditingAllowed() {
    return this.msalBasedAuthorizationService.hasReconciliationRole(RECONCILATIONS_CONFIG.roles.writeRole);
  }

  get firstDataItem(): ProcessedConnectionByVVV {
    return this.data?.data && this.data.data.length > 0 && this.data.data[0] ? this.data.data[0] : undefined;
  }

  get isLoading(): boolean {
    return this.isLoadingData || this.isLoadingParentData;
  }

  get maxPage() {
    return this.data ? Math.ceil(this.data.totalCount / this.itemsPerPage) : 1;
  }

  get dataCountIsLargerThenMinimumPageSize(): boolean {
    const minimumPageSize = this.pageSizes && this.pageSizes.length ? this.pageSizes[0] : 0;
    return this.data.totalCount > minimumPageSize;
  }

  get formExternalReference(): string {
    return this.formDataGroup.get('externalReference')?.value ?? '';
  }

  get formIsCorrected(): boolean {
    const isCorrected = this.formDataGroup.get('isCorrected').value;

    return isCorrected;
  }

  onChangeExternalReference() {
    const isCorrected = this.formIsCorrected;

    // Store original value in global variable if true to reset when external reference was cleared during editing and then re-filled again
    if (isCorrected === true) {
      this.wasOrginallyCorrectedTrue = true;
    }

    if (!this.isExternalReferenceFilled() && isCorrected === true) {
      // Set form value to false if external reference is cleared
      this.formDataGroup.controls.isCorrected.setValue(false);
    } else if (this.isExternalReferenceFilled() && this.wasOrginallyCorrectedTrue === true && isCorrected === false) {
      // Set form value to original if external reference is filled again and a orginal value is saved
      this.formDataGroup.controls.isCorrected.setValue(true);
    }
  }

  ngOnInit() {
    this.detailIdFromRoute = this.route.snapshot.paramMap.get('id');

    this.initialiseData();
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach((s) => s.unsubscribe());
    }
  }

  initialiseData() {
    this.checkRouterParams();
    this.fetchData();
  }

  checkRouterParams() {
    this.subscriptions.push(
      this.route.queryParamMap.subscribe((paramsMap: any) => {
        if (paramsMap.keys.includes('search')) {
          this.searchString = paramsMap.params.search;
        }
      }),
    );
  }

  checkPage() {
    if (this.route.snapshot.queryParams.page) {
      this.goToPage(Number.parseInt(this.route.snapshot.queryParams.page, 10));
    } else {
      this.initialisePage(1);
    }
  }

  checkPageSize() {
    if (this.route.snapshot.queryParams.size) {
      this.setPageSize(this.route.snapshot.queryParams.size);
    } else {
      const pageSize = localStorage.getItem('reconciliations-volumes-size');
      if (pageSize) {
        this.setPageSize(pageSize);
      }
    }
  }

  goToConnection(connectionEan: string) {
    const url: any = this.router.createUrlTree(['/connection', connectionEan]);
    window.open(url, '_blank');
  }

  async downloadJson(dataItem: ProcessedConnectionByVVV) {
    const allocationProgressDetailSeries = await lastValueFrom(
      this.reconciliationAllocationService.getAllocationProgressDetailSeriesVVVByAllocationProgressId(dataItem.allocationProgressLVId),
    );

    const json = allocationProgressDetailSeries?.detail_Series_Json;

    if (json === undefined) {
      this.notificationService.showDanger('VVV aansluiting heeft geen download data.');
    } else {
      const gridOperatorFileNamePart = this.determineGridOperatorFilePart(dataItem);

      const datePipe = new DatePipe('nl-nl');
      const filename =
        'VVV' +
        (dataItem.connectionEan ? '-' + dataItem.connectionEan : '') +
        (gridOperatorFileNamePart ? '-Grid-' + this.transformToValidFileName(gridOperatorFileNamePart) : '') +
        '-' +
        datePipe.transform(dataItem.processDate, 'YYYY-MM');

      BlobCreateHelper.downloadJson(filename, json);

      this.notificationService.showSuccess('VVV aansluiting download gestart.');
    }
  }

  determineGridOperatorFilePart(dataItem: ProcessedConnectionByVVV) {
    return dataItem.gridOperatorEan ? dataItem.gridOperatorEan : dataItem.gridOperatorName;
  }

  transformToValidFileName(data: string) {
    return data.replace(/[/\\?%*:|"<>]/g, '-');
  }

  determinedDetailId(): string {
    if (this.detailId === undefined) {
      this.detailId = localStorage.getItem('reconciliations-detailId');
    }
    return this.detailId ?? this.detailIdFromRoute;
  }

  fetchData() {
    if (!this.isLoadingData && !this.isLoadingParentData) {
      this.isLoadingData = true;
      this.isLoadingParentData = true;
      this.getDataFromApi();
    }
  }

  getDataFromApi() {
    const detailId = this.determinedDetailId();

    if (detailId !== undefined && detailId !== null && detailId !== '') {
      this.subscriptions.push(
        this.reconciliationAllocationService.getAllocationProgressById(detailId).subscribe((result: AllocationProgressLV) => {
          this.parentData = result;
          this.isLoadingData = false;
        }),
        this.reconciliationAllocationService
          .getAllValidProcessedConnectionsByVVVByValidAllocationProgressId(detailId)
          .subscribe((results: ProcessedConnectionByVVV[]) => {
            try {
              if (results) {
                const pagedData = Page.readArrayObject<ProcessedConnectionByVVV>(results, 'ProcessedConnectionByVVV');
                pagedData.data = pagedData.data
                  .filter((x) => x.connectionEan === this.connectionEan)
                  .sort((a, b) => a.consumptionEndDateTime.toString().localeCompare(b.consumptionEndDateTime.toString()));
                this.data = pagedData;

                this.totalItems = this.data.totalCount;
                this.paginateConfig.totalItems = this.data.totalCount;
              }

              if (results && this.data.totalCount > 0) {
                this.initFormParentData(this.firstDataItem);
                this.checkPageSize();
                this.checkPage();
              }
            } catch (err: any) {
              throw new Error(err);
            } finally {
              this.isLoadingParentData = false;
            }
          }),
      );
    }
  }

  pageInUrlIsDifferent(pageNumber: number): boolean {
    const url = new URL(location.href);
    const searchParams = url.searchParams;
    if (searchParams.has('page')) {
      return url.searchParams.get('page') !== pageNumber.toString();
    }
    return true;
  }

  goToPage(pageNumber: number) {
    // Check if page is set to an incorrect value, reset to 1
    if (pageNumber < 0 || pageNumber > this.maxPage) {
      pageNumber = 1;
    }

    const pageInUrlIsDifferent = this.pageInUrlIsDifferent(pageNumber);

    if (pageInUrlIsDifferent) {
      const url = new URL(location.href);
      const searchParams = url.searchParams;
      if (searchParams.has('page')) {
        url.searchParams.set('page', pageNumber.toString());
      } else {
        url.searchParams.append('page', pageNumber.toString());
      }

      history.pushState(null, null, url);
    }

    this.initialisePage(pageNumber);
  }

  initialisePage(pageNumber: number) {
    // Check if page is set to an incorrect value, reset to 1
    if (pageNumber < 0 || pageNumber > this.maxPage) {
      pageNumber = 1;
    }

    this.currentPage = pageNumber;
    this.paginateConfig.currentPage = pageNumber;

    // this.fetchData(); // TODO: DW: Do this when using Paging in API
  }

  pageSizeInUrlIsDifferent(pageSize: number): boolean {
    const url = new URL(location.href);
    const searchParams = url.searchParams;
    if (searchParams.has('size')) {
      return url.searchParams.get('size') !== pageSize.toString();
    }
    return true;
  }

  setPageSize(pageSize: string) {
    let pageSizeValue = Number(pageSize);

    // Check if page is set to an incorrect value, reset to 1
    if (pageSizeValue < 0 || !this.pageSizes.includes(pageSizeValue)) {
      pageSizeValue = RECONCILATIONS_CONFIG.pagesizes.volumes;
    }

    const pageSizeInUrlIsDifferent = this.pageSizeInUrlIsDifferent(pageSizeValue);

    if (pageSizeInUrlIsDifferent) {
      const url = new URL(location.href);
      const searchParams = url.searchParams;
      if (searchParams.has('size')) {
        url.searchParams.set('size', pageSize.toString());
      } else {
        url.searchParams.append('size', pageSize.toString());
      }

      history.pushState(null, null, url);
    }

    this.itemsPerPage = pageSizeValue;
    this.paginateConfig.itemsPerPage = pageSizeValue;
    localStorage.setItem('reconciliations-volumes-size', pageSizeValue.toString());
  }

  onPageSizeChange(pageSize: string): void {
    this.setPageSize(pageSize);
    this.goToPage(1);
  }

  initFormParentData(dataItem: ProcessedConnectionByVVV) {
    if (dataItem) {
      this.formDataGroup.controls.externalReference.setValue(dataItem.externalReference);
      this.formDataGroup.controls.isCorrected.setValue(dataItem.isCorrected ?? false);
    }
  }

  isExternalReferenceFilled() {
    const externalReference = this.formExternalReference;

    return externalReference !== undefined && externalReference !== null && externalReference.trim() !== '';
  }

  isDataChanged(dataItem: ProcessedConnectionByVVV): boolean {
    const externalReference = this.formExternalReference;
    const isCorrected = this.formIsCorrected;

    return dataItem && (dataItem.externalReference !== externalReference || (dataItem.isCorrected ?? false) !== (isCorrected ?? false));
  }

  cancelChanges(event: any, dataItem: ProcessedConnectionByVVV) {
    event.preventDefault();

    this.initFormParentData(dataItem);
  }

  saveChanges(event: any, dataItem: ProcessedConnectionByVVV) {
    const externalReference = this.formExternalReference;
    const isCorrected = this.formIsCorrected;

    event.preventDefault();

    // if (this.isDataChanged(dataItem)) {
    //   if (externalReference !== undefined && externalReference !== null) {
    //     this.updateExternalReferenceAndIsCorrected(dataItem, externalReference, isCorrected);
    //   }
    // }

    if (this.isDataChanged(dataItem)) {
      if (externalReference !== undefined && externalReference !== null) {
        this.subscriptions.push(
          this.reconciliationAllocationService
            .updateProcessedConnectionsByVVVExternalReferenceByAllocationProgressConnectionEan(
              dataItem.allocationProgressLVId,
              dataItem.connectionEan,
              externalReference,
            )
            .subscribe((result: ProcessedConnectionByVVV) => {
              dataItem = result;
            }),
        );

        dataItem.externalReference = externalReference;

        if (isCorrected !== undefined) {
          this.subscriptions.push(
            this.reconciliationAllocationService
              .updateProcessedConnectionsByVVVIsCorrectedByAllocationProgressConnectionEan(
                dataItem.allocationProgressLVId,
                dataItem.connectionEan,
                isCorrected,
              )
              .subscribe((result: ProcessedConnectionByVVV) => {
                dataItem = result;
                this.notificationService.showSuccess('VVV aansluiting opgeslagen.');

                // Reset stored is corrected value, to update upon next check
                this.wasOrginallyCorrectedTrue = false;
              }),
          );

          dataItem.isCorrected = isCorrected;

          // Reset stored is corrected value, to update upon next check
          this.wasOrginallyCorrectedTrue = false;
        }
      }
    }
  }

  updateExternalReferenceAndIsCorrected(dataItem: ProcessedConnectionByVVV, externalReference?: string, isCorrected?: boolean): void {
    const allocationProgressById = dataItem.allocationProgressLVId;
    const connectionEan = dataItem.connectionEan;

    this.subscriptions.push(
      this.reconciliationAllocationService
        .updateProcessedConnectionsByVVVExternalReferenceByAllocationProgressConnectionEan(
          allocationProgressById,
          connectionEan,
          externalReference.trim(),
        )
        //       .pipe(
        //         map((result: ProcessedConnectionByVVV) => {
        //           dataItem = result;
        //         }),
        //         mergeMap((): Observable<ProcessedConnectionByVVV> => {
        //           if (isCorrected !== undefined) {
        //             return this.reconciliationAllocationService.updateProcessedConnectionsByVVVIsCorrectedByAllocationProgressConnectionEan(
        //               allocationProgressById,
        //               connectionEan,
        //               isCorrected,
        //             );
        //           }

        //           return of(dataItem);
        //         }),
        //       )
        .subscribe((result: ProcessedConnectionByVVV) => {
          dataItem = result;
          this.notificationService.showSuccess('VVV aansluiting opgeslagen.');
        }),
    );
  }
}
