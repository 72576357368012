export const RECONCILATIONS_CONFIG = {
  definitions: {
    allocationProgress: {
      externalReferenceMaxLength: 255,
    },
    processedConnectionByVVV: {
      externalReferenceMaxLength: 255,
    },
  },
  pagesizes: {
    overview: 10,
    details: 25,
    volumes: 20,
  },
  roles: {
    readRole: 'Reconciliation.Read',
    writeRole: 'Reconciliation.Write',
  },
  baseApiUrls: {
    processedConnectionsByVVV: {
      all: '/processed-connections-by-vvv',
      allValid: '/processed-connections-by-vvv-valid',
      allocationProgresses: {
        item: {
          base: '/processed-connections-by-vvv/allocation-progresses/{0}',
          valid: '/processed-connections-by-vvv-valid/allocation-progresses-valid/{0}',
          connections: {
            item: {
              externalReference: '/processed-connections-by-vvv/allocation-progresses/{0}/connections/{1}/external-reference',
              isCorrected: '/processed-connections-by-vvv/allocation-progresses/{0}/connections/{1}/is-corrected/{2}',
            },
          },
        },
      },
      item: '/processed-connections-by-vvv/{0}',
    },
    allocationProgresses: {
      all: '/allocation-progresses',
      allValid: '/allocation-progresses-valid',
      item: {
        base: '/allocation-progresses/{0}',
        externalReference: '/allocation-progresses/{0}/external-reference',
      },
    },
    allocationProgressLVDetailSeriesVVV: {
      item: '/allocation-progresses-detail-series-vvv/{0}',
      allocationProgresses: {
        item: {
          base: '/allocation-progresses-detail-series-vvv/allocation-progresses/{0}',
        },
      },
    },
  },
  policyGroups: {
    reconciliationReader: 'RECONCILIATION_READER',
    reconciliationWriter: 'RECONCILIATION_WRITER',
  },
};
