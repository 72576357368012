import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';

import { ErrorService } from './Services/error.service';
import { NotificationService } from './services/notification.service';

// import { LoggingService } from './services/logging.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.
  // if the current solution gives problems, with  using the constructor, we can go back to using the injector (private injector:Injector)
  constructor(private notification: NotificationService, private errorService: ErrorService) {}

  async handleError(error: any): Promise<void> {
    let message: string;
    let stackTrace: string;
    let userMessage: string;

    if (error instanceof HttpErrorResponse) {
      // Server Error
      message = this.errorService.getServerMessage(error);
      stackTrace = await this.errorService.getServerStack(error);
      userMessage = message + '\n' + 'StackTrace: \n' + stackTrace;
    } else if (error instanceof Error) {
      // Client Error
      message = this.errorService.getClientMessage(error);
      stackTrace = this.errorService.getClientStack(error);
      userMessage = message;
    } else {
      // Other Error
      message = this.errorService.getServerMessage(error);
      stackTrace = await this.errorService.getServerStack(error);
      userMessage = message + '\n' + 'StackTrace: \n' + stackTrace;
    }

    // Log to notification center
    this.errorService.addError(error);

    // Feedback to user
    this.notification.showError(userMessage);

    // Always log errors
    // logger.logError(message, stackTrace);
    console.error(error);
  }
}
