import { DateTime } from 'luxon';

import { environment } from '@environment';

export const DEFAULT_TIMEZONE = 'Europe/Amsterdam';
export const MOMENT_DATETIME_API_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const MOMENT_ZONED_DATETIME_API_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const MOMENT_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const MOMENT_DATE_FORMAT = 'YYYY-MM-DD';
export const LUXON_DATE_FORMAT = 'yyyy-MM-dd';
export const LUXON_DATETIME_FORMAT = 'yyyy-MM-dd T';
export const FROM_FORMAT_API_ZONED = "yyyy-MM-dd'T'HH:mm:ss:SZZ"; // 2022-07-29T01:30:00+02:00
export const FROM_FORMAT_API = "yyyy-MM-dd'T'HH:mm:ss:S"; // 2022-07-29T01:30:00 // https://github.com/moment/luxon/blob/master/docs/formatting.md
export const FROM_FORMAT_API_S = "yyyy-MM-dd'T'HH:mm:ss";
export const FROM_FORMAT_API_SZ = "yyyy-MM-dd'T'HH:mm:ssZZ";
export const TODAY_DATE = () => DateTime.now().toFormat(LUXON_DATE_FORMAT);
export const TODAY_DATETIME = () => DateTime.now().toFormat(LUXON_DATETIME_FORMAT);

export const HEADER_FILETYPE = {
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XML: 'application/xml',
};

export const DEFINE_URL = () => environment.getOperationsApiUrl() + '/api';
export const TRADING_API_URL = () => environment.getTradingApiUrl();
