<div class="row dashboard-content">
    <div class="col-sm-12">
        <div class="card dashboard-card">
            <div class="card-header dashboard-card-title">
                <div class="float-left">
                    <app-back-button>
                    </app-back-button>
                </div>
                VVV verwerking
            </div>
            <div class="card-body">
                <ul class="nav nav-pills">
                    <li>
                        <a class="nav-link" [ngClass]="{'active': tab === 0}" href="reconciliations?tab=0&page=1"
                            (click)="(tab !== 0) ? selectTab(0) : false">Overzicht</a>
                    </li>
                    <li [ngClass]="{'tab-disabled': isNotSet(detailId$ | async)}">
                        <a class="nav-link" [ngClass]="{'active': tab === 1}" href="reconciliations?tab=1&page=1"
                            (click)="(tab !== 1) ? selectTab(1) : false">Details {{detailsTabTitle$ | async}}</a>
                    </li>
                    <li [ngClass]="{'tab-disabled': isNotSet(detailId$ | async) || isNotSet(connectionEan$ | async)}">
                        <a class="nav-link" [ngClass]="{'active': tab === 2}" href="reconciliations?tab=2&page=1"
                            (click)="(tab !== 2) ? selectTab(2) : false">Verbruik- & verrekengegevens {{volumesTabTitle$
                            | async}}</a>
                    </li>
                </ul>
                <div class="tab-wrap">
                    <app-reconciliations-overview-component *ngIf="isInitialized(0)" [hidden]="tab !== 0"
                        (openDetailEvent)="openDetail($event)" (noDataEvent)="noOverviewData($event)">
                    </app-reconciliations-overview-component>
                    <app-reconciliations-details-component *ngIf="isInitialized(1)" [hidden]="tab !== 1"
                        [detailId]="detailId$ | async" (openVolumeEvent)="openVolume($event)">
                    </app-reconciliations-details-component>
                    <app-reconciliations-volumes-component *ngIf="isInitialized(2)" [hidden]="tab !== 2"
                        [detailId]="detailId$ | async" [connectionEan]="connectionEan$ | async">
                    </app-reconciliations-volumes-component>
                </div>
            </div>
        </div>
    </div>
</div>