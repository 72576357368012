import { ObjectParser } from '../Connection';

export class AllocationProgressLV {
  public id: string;
  public senderCorrelationID: string;
  public receiverCorrrelationID: string;
  public senderEan: string;
  public receiverEan: string;
  public receiverName: string;
  public messageID: string;
  public mrid: string;
  public technicalMessageId: string;
  public externalReference: string;
  public receivedOn: Date;
  public gridOperatorName: string;
  public processedDate: Date;
  public productType: string;
  public productTypeName: string;
  public totalPrice: number;
  public correctedOn: Date;
  public isCorrected: boolean;
  private _obj: any;

  public static readObject(map: any): AllocationProgressLV {
    const c = new AllocationProgressLV();
    // eslint-disable-next-line no-underscore-dangle
    c._obj = map;
    return ObjectParser.fillObject(c, map);
  }

  public toString = (): string => JSON.stringify(this);
}
