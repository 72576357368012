import { ServiceDeskError } from 'src/app/Services/error.service';
import { NotificationService } from 'src/app/services/notification.service';

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
})
export class NotificationCenterComponent implements OnInit, OnChanges {
  @Input() errors: ServiceDeskError[];
  @Output() closeNotificationCenter = new EventEmitter<boolean>();

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.sort();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.errors) {
      this.sort();
    }
  }
  sort() {
    this.errors.sort((a: ServiceDeskError, b: ServiceDeskError) => b.timeStamp.getTime() - a.timeStamp.getTime());
  }

  close() {
    this.closeNotificationCenter.emit(true);
  }

  copyToClipboard = (error) => {
    const el = document.createElement('textarea');
    el.value = `URL: ${error.url}
    Error: ${error.error}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.notificationService.showSuccess('Foutmelding gekopïeerd na klembord');
  };
}
