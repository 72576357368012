import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { ReconciliationsDashboardComponent } from './reconciliations-dashboard.component/reconciliations-dashboard.component';
import { ReconciliationsDetailsComponent } from './reconciliations-details.component/reconciliations-details.component';
import { RECONCILIATIONS_ROLES } from './reconciliations-roles';
import { ReconciliationsVolumesComponent } from './reconciliations-volumes.component/reconciliations-volumes.component';

export const reconciliationsRoutes: Routes = [
  {
    path: 'reconciliations',
    component: ReconciliationsDashboardComponent,
    canActivate: [MsalGuard],
    data: {
      roles: RECONCILIATIONS_ROLES.reconciliationsOverview,
    },
  },
  {
    path: 'reconciliations/details/:id',
    component: ReconciliationsDetailsComponent,
    canActivate: [MsalGuard],
    data: {
      roles: RECONCILIATIONS_ROLES.reconciliationsDetails,
    },
  },
  {
    path: 'reconciliations/details:id/volumes',
    component: ReconciliationsVolumesComponent,
    canActivate: [MsalGuard],
    data: {
      roles: RECONCILIATIONS_ROLES.reconciliationsVolumes,
    },
  },
];
