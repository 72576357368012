import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { ConnectionComponent } from './Components/ConnectionComponent/ConnectionComponent';
import { ContractStateComponent } from './Components/ContractStateComponent/ContractStateComponent';
import { CustomerComponent } from './Components/CustomerComponent/CustomerComponent';
import { IssueComponent } from './Components/IssueComponent/IssueComponent';
import { LoginComponent } from './Components/login.component';
import { MinimalApiTestComponent } from './Components/minimal-api-test/minimal-api-test.component';
import { NotFoundComponent } from './Components/not-found/not-found.component';
import { AcmDashboard } from './Dashboards/AcmDashboard/AcmDashboard';
import { AdminDashboardComponent } from './Dashboards/admin-dashboard/admin-dashboard.component';
import { ContractStateDashboard } from './Dashboards/ContractStateDashboard/ContractStateDashboard';
import { CustomerDashboard } from './Dashboards/CustomerDashboard/CustomerDashboard';
import { EdmDashboardComponent } from './Dashboards/edm-dashboard/edm-dashboard.component';
import { ExternalDossierDashboardComponent } from './Dashboards/external-dossier-dashboard/external-dossier-dashboard.component';
import { HomeDashboardComponent } from './Dashboards/home-dashboard/home-dashboard.component';
import { IssuesDashboard } from './Dashboards/Issues/IssuesDashboard';
import { ProcessDashboard } from './Dashboards/ProcessDashboard/ProcessDashboard';
import { reconciliationsRoutes } from './Dashboards/Reconciliations/reconciliations.routes';
import { SearchDashboard } from './Dashboards/search-dashboard/search-dashboard.component';
import { StyleguideComponent } from './Dashboards/styleguide/styleguide.component';
import { TranslationDashboard } from './Dashboards/TranslationDashboard/TranslationDashboard';
import { TranslationDashboardEdit } from './Dashboards/TranslationDashboard/TranslationDashboardEdit';

const routes: Routes = [
  { path: '', redirectTo: 'home-dashboard', pathMatch: 'full' },
  {
    path: 'home-dashboard',
    component: HomeDashboardComponent,
    canActivate: [MsalGuard],
  },
  { path: 'acm-dashboard', component: AcmDashboard, canActivate: [MsalGuard] },
  {
    path: 'search-dashboard',
    component: SearchDashboard,
    canActivate: [MsalGuard],
  },
  {
    path: 'issues-dashboard',
    component: IssuesDashboard,
    canActivate: [MsalGuard],
  },
  {
    path: 'edm-dashboard',
    component: EdmDashboardComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'process-dashboard',
    component: ProcessDashboard,
    canActivate: [MsalGuard],
  },
  {
    path: 'external-dossier-dashboard',
    component: ExternalDossierDashboardComponent,
    canActivate: [MsalGuard],
  },
  { path: 'login', component: LoginComponent, canActivate: [MsalGuard] },
  {
    path: 'connection/:connectionid',
    component: ConnectionComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'connection/:connectionid/issue/:issueid',
    component: IssueComponent,
    canActivate: [MsalGuard],
  },
  // { path: 'connection/:connectionid/customer/:customerid', component: CustomerComponent, canActivate: [MsalGuard] },
  {
    path: 'contract-state-dashboard',
    component: ContractStateDashboard,
    canActivate: [MsalGuard],
  },
  {
    path: 'contractstate/:contractstateid',
    component: ContractStateComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'customer-dashboard',
    component: CustomerDashboard,
    canActivate: [MsalGuard],
  },
  { path: 'admin', component: AdminDashboardComponent, canActivate: [MsalGuard] },
  {
    path: 'customer/:customerId',
    component: CustomerComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'translation',
    component: TranslationDashboard,
    canActivate: [MsalGuard],
  },
  {
    path: 'translation/:translationDomainName/:name',
    component: TranslationDashboardEdit,
    canActivate: [MsalGuard],
  },
  {
    path: 'translation/create',
    component: TranslationDashboardEdit,
    canActivate: [MsalGuard],
  },
  {
    path: 'styleguide',
    component: StyleguideComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'contract-state-transitioning',
    loadChildren: () =>
      import('./modules/contract-state-transitioning/contract-state-transitioning.module').then((m) => m.ContractStateTransitioningModule),
  },
  {
    path: 'timeseries',
    canActivate: [MsalGuard],
    loadChildren: () => import('./modules/timeserie/timeserie.module').then((m) => m.TimeserieModule),
  },
  {
    path: 'trading',
    canActivate: [MsalGuard],
    loadChildren: () => import('./modules/trading/trading.module').then((m) => m.TradingModule),
  },
  {
    path: 'minimalapitest',
    component: MinimalApiTestComponent,
  },
  {
    path: 'customerportal',
    loadChildren: () => import('./modules/customer-portal-helper/customer-portal-helper.module').then((m) => m.CustomerPortalHelperModule),
  },
  ...reconciliationsRoutes,
  {
    // default -> if not found, then...
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
