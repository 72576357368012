import { ObjectParser } from '../Connection';

export class ProcessedConnectionByVVV {
  public id: string;
  public allocationProgressLVId: string;
  public connectionEan: string;
  public gridOperatorEan: string;
  public gridOperatorName: string;
  public isCorrected: boolean;
  public processDate: Date;
  public createdOnVVV: Date;
  public receivedOnVVV: Date;
  public createdOn: Date;
  public receiverEan: string;
  public receiverName: string;
  public consumptionStartDateTime: Date;
  public consumptionEndDateTime: Date;
  public validVVV: boolean; //TODO: DW: rename to IsValidVVV (and add JsonProperty in API)
  public externalReference: string;
  private _obj: any;

  public static readObject(map: any): ProcessedConnectionByVVV {
    const c = new ProcessedConnectionByVVV();
    const mp: ProcessedConnectionByVVV = map as ProcessedConnectionByVVV;
    // eslint-disable-next-line no-underscore-dangle
    c._obj = map;

    return ObjectParser.fillObject(c, map);
  }

  public toString = (): string => JSON.stringify(this);
}
