import { ObjectParser } from '../Connection';

export class AllocationProgressLVDetailSeriesVVV {
  public id: string;
  public allocationProgressLVId: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public detail_Series_Json: string;
  private _obj: any;

  public static readObject(map: any): AllocationProgressLVDetailSeriesVVV {
    const c = new AllocationProgressLVDetailSeriesVVV();
    // eslint-disable-next-line no-underscore-dangle
    c._obj = map;
    return ObjectParser.fillObject(c, map);
  }

  public toString = (): string => JSON.stringify(this);
}
