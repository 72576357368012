import moment, { Moment } from 'moment';

// Rename to Domain instead of connection. Cause hèy, it's really that.
import { MOMENT_DATE_FORMAT, MOMENT_TIME_FORMAT } from './constants';
import { StringToEnum } from './helpers/EnumHelper';
import { ExternalDossierId } from './modules/operations-api';
import { TimeTools } from './services/time-tools.service';

export class ObjectParser {
    public static readObject(key: string, value: any): any {
        if (typeof (value) === 'string' || typeof (value) === 'number' || typeof (value) === 'boolean' || value === null) {
            if (isNaN(Number(key)) && eval("typeof " + key) !== 'undefined' && eval("typeof " + key + ".readObject") !== 'undefined') {
                return ObjectParser.fillOrRead(key, value);
            }
            // If the value is a zero and type a number it can be detected, and then returns 0. Is this where our zero's dissappear?
            //value === 0 ? console.log('0 found') : console.log('no 0')
            return value;
        } else if (Array.isArray(value)) {
            return ObjectParser.readArray(key, value);
        } else {
            try {
                return ObjectParser.fillOrRead(key, value);
            } catch (error) {
                console.error(error);
                console.error("Unable to create Object for key: " + key + ":" + typeof (value) + ":" + value);
                throw new Error("Unable to create Object");
            }
        }
    }
    public static fillObject<T>(obj: T, map: any): T {
        let retObj = {};

        if (typeof obj === 'string') {

            retObj['_str'] = obj;
            return retObj as T;
        }

        retObj = { '_obj': obj };

        for (const key in map) {
            if (Object.prototype.hasOwnProperty.call(map, key)) {
                const parsed = ObjectParser.readObject(key, map[key]);
                if (parsed) {
                    retObj[key] = parsed;
                }
            }
        }
        return retObj as unknown as T;
    }

    public static readArray<T>(key: string, value: any): T[] {
        return value.map(c => ObjectParser.fillOrRead(key.substring(0, key.length - 1), c));
    }

    public static fillOrRead(key: string, value: any) {
        if (eval("typeof " + key) === 'undefined') {
            // console.error("Class not found for " + key + " ,with value: " + JSON.stringify(value));
            // Return null was changed to return value, this is to provide ProcessProgress with the context [{..}, {...}], else it returned [ null, null ]
            return value;
        }

        if (eval("typeof " + key + ".readObject") === 'undefined') {
            try {
                // var obj = eval("new " + key + "()");
                return ObjectParser.fillObject(value, value);
            } catch (error) {
                console.error("No empty constructor for " + key);
                // Return null was changed to return value, this is to provide ProcessProgress with the context [{..}, {...}], else it returned [ null, null ]
                return value;
                // return null;
            }
        } else {
            const filled = ObjectParser.fillObject(value, value);
            filled['_obj'] = value;
            return eval(key + '.readObject(filled)');
        }
    }
}

export class Connection {
    public _obj: any;
    public Id: string;
    public ConnectionMeters: ConnectionMeter[];
    public ConnectionContacts: ConnectionContact[];
    public ConnectionProfiles: ConnectionProfile[];
    public CustomerConnections: CustomerConnection[];
    public ProductType: ProductType;
    public ConnectionClusters: ConnectionCluster[];
    public ConnectionConnectionCapacities: ConnectionConnectionCapacitie[];
    public ConnectionTariffSwitches: any[];
    public ConnectionLocations: ConnectionLocation[];
    public ConnectionSupplyTypes: ConnectionSupplyType[];
    // public ConnectionContextValues: ConnectionContextValue[]; // Turned off for dependency reasons, if changed, it breaks.
    public ConnectionContextValues: any[];
    // public TransactionDossierSteps: TransactionDossierStep[];  // Turned off for dependency reasons, if changed, it breaks.
    public ConnectionAllocationMethods: ConnectionAllocationMethod[];
    public ConnectionPhysicalStates: ConnectionPhysicalState[]; // toDefine
    public ExternalDossier: ExternalDossier[];
    public ConnectionEstimatedAnnualProsumptions: ConnectionEstimatedAnnualProsumption[];
    public ConnectionGridAreas: ConnectionGridArea[];
    public ConnectionGridOperators: ConnectionGridOperator[];

    public static readObject(map: any): Connection {
        const c = new Connection();
        // eslint-disable-next-line no-underscore-dangle
        c._obj = map;
        return ObjectParser.fillObject(c, map);
    }

    public toString = (): string => JSON.stringify(this);
}

export class Page<T> {
    public totalCount: number;
    public offset: number;
    public data: T[];
    constructor(totalCount: number, offset: number, data: T[]) {
        this.offset = offset;
        this.totalCount = totalCount;
        this.data = data;
    }

    public static readPageObject<T>(map: any, pageType: string): Page<T> {
        return new Page<T>(map.TotalCount, map.Offset, ObjectParser.readArray(pageType + 's', map.Data) as T[]);
    }

    public static readArrayObject<T>(items: any[], pageType: string): Page<T> {
        return new Page<T>(items.length, 0, ObjectParser.readArray(pageType + 's', items) as T[]);
    }
}
export class MeasurementsPage extends Page<Measurement[]>{
    public static readObject(map: any): Page<Issue> {
        return Page.readPageObject<Issue>(map, "Measurements");
    }
}
export class Timestamp {

    public moment: moment.Moment;

    constructor(moment: any) {
        this.moment = moment;
        // if(moment!=null){
        //     // Check if it is a timestamp already, or just a moment
        //     this.moment = moment(moment.moment === undefined ? moment : moment.moment)
        //     //moment(moment.moment === undefined ? moment : moment.moment);
        // }
    }
    // this.moment = moment;
    public valueOf = (): number => this.moment.valueOf();


    public toString(format?: string, zoned?: boolean) {
        let retValue;
        if (zoned) {
            // console.log(this.moment.format())
            retValue = '' + this.moment.format() + (this.moment.tz() === undefined ? '' : (' ' + this.moment.tz()))
            return retValue
        }
        format = format != null ? format : 'LLLL';
        retValue = this.moment.format(format)
        return retValue
    }

    public toNodaZonedDateTimeString() {
        return this.moment.format('YYYY-MM-DDTHH:mm:ss:SSZ')
    }

    public static readObject(map: any): Timestamp {
        if (map == null) {
            return null;
        }

        if (map instanceof Timestamp) {
            return map;
        }

        if (map['_str']) {
            var split = map['_str'].split(' ');
            return new Timestamp(moment.tz(split[0], split[1]));
        }

        if (map.split === undefined) {
            // console.log('no split');
            // console.log(new Timestamp(moment(map)))
            return new Timestamp(moment(map));
        }
        var split = (map as any).split(' ');
        return new Timestamp(moment.tz(split[0], split[1]));
    }
}
export class ModifiedTimestamp extends Timestamp {
}

export enum BoundaryType {
    Open,
    Closed,
}
export class DateRange {
    public From?: Timestamp;
    public Until?: Timestamp;
    public LowerBoundType: BoundaryType;
    public UpperBoundType: BoundaryType;

    constructor(from?: Timestamp, lowerBoundType?: BoundaryType, until?: Timestamp, upperBoundType?: BoundaryType) {
        this.From = from;
        this.Until = until;
        this.LowerBoundType = lowerBoundType == null ? BoundaryType.Open : lowerBoundType;
        this.UpperBoundType = upperBoundType == null ? BoundaryType.Open : upperBoundType;
    }
    public toRangeString(format: string): string {

        var retValue: string = "";
        if (this.LowerBoundType === BoundaryType.Closed) {
            retValue += '[';
        } else {
            retValue += '(';
        }
        if (this.From != null) {
            // retValue += this.From.toString(format, true);
            retValue += this.From.toString(format);
        }
        retValue += ',';
        if (this.Until != null) {
            // Does not yet work with the right timeformat
            // retValue += this.Until.toString(format, true);
            retValue += this.Until.toString(format);
        }
        if (this.UpperBoundType === BoundaryType.Closed) {
            retValue += ']';
        } else {
            retValue += ')';
        }
        return retValue;
    }
    public static readObject(map: any): DateRange {
        // console.log(map['From']);
        // console.log(map['Until']);

        if (map['From'] != null || map['Until'] != null) {
            return new DateRange(Timestamp.readObject(map['From']), BoundaryType.Closed, Timestamp.readObject(map['Until']), BoundaryType.Open);
        }
        var inputString = map as string;
        var lowerBoundType = inputString.startsWith('(') ? BoundaryType.Open : BoundaryType.Closed;
        var upperBoundType = inputString.endsWith(')') ? BoundaryType.Open : BoundaryType.Closed;
        var bounds = inputString.replace('(', '').replace('[', '').replace(')', '').replace(']', '').split(',');
        var from = (bounds[0] == "" || bounds[0] == "?") ? null : Timestamp.readObject(bounds[0]);
        var until = (bounds[1] == "" || bounds[1] == "?") ? null : Timestamp.readObject(bounds[1]);
        return new DateRange(from, lowerBoundType, until, upperBoundType);
    }
}


export class Interval {
    constructor(
        public From?: moment.Moment,
        public Until?: moment.Moment
    ) { }

    public static readObject(map: any): Interval {
        if (typeof map.From === 'string') {
            map = { '_str': map };
        }
        if (typeof map === 'string') {

            map = { '_str': map };
        }

        if (map['From'] && moment.isMoment(map['From'])) {
            return new Interval(
                map['From'],
                map['Until']
            );
        }

        if (map['From'] && map.From.UtcDateTime && map.From.Zone) {
            return new Interval(
                moment.utc(map.From.UtcDateTime).tz(map.From.Zone),
                moment.utc(map.Until.UtcDateTime).tz(map.Until.Zone)
            );
        }

        if (map['_str']) {

            let dr = DateRange.readObject(map['_str']);
            // console.log(new Interval(dr.From, dr.Until));
            return new Interval(dr.From != null ? dr.From.moment : null, dr.Until != null ? dr.Until.moment : null);
        }

        return new Interval(
            TimeTools.utcDateTimeObjectToMoment(map['From']),
            TimeTools.utcDateTimeObjectToMoment(map['Until'])
        );
    }

    public toDateRangeString(): string {
        return this.toRangeString(MOMENT_DATE_FORMAT);
    }
    public toDateTimeRangeString(): string {
        return this.toRangeString(MOMENT_TIME_FORMAT);
    }

    public ToDateRange(): DateRange {
        return new DateRange(
            this.From ? new Timestamp(this.From) : null,
            BoundaryType.Closed,
            this.Until ? new Timestamp(this.Until) : null,
            BoundaryType.Open);
    }

    public toRangeString(format: string): string {
        var retValue = "[";
        retValue += this.From != null ? this.From.local().format(format) : '';
        retValue += ","
        retValue += this.Until != null ? this.Until.local().format(format) : '';
        retValue += ")"
        return retValue;
    }
    public toClosedDateRangeString(format: string): string {
        var retValue = "[";
        retValue += this.From != null ? this.From.local().format(format) : '';
        retValue += ","
        retValue += this.Until != null ? this.Until.local().format(format) : '';
        retValue += "]"
        return retValue;
    }

    public days(): number {
        return this.Until == null || this.From == null ? -1 : this.Until.diff(this.From, 'days');
    }

    public durationInGranularity(granularity?: Granularity): number {
        return this.Until && this.From ? this.Until.diff(this.From, granularity as any) : null;
    }
}

export class PersonalName {
}
export class Address {
}
export class ConnectionLocation {
    constructor(
        public Address: Address,
        public Interval: Interval,
        public Legislation: Legislation,
    ) { }

    public static readObject(map: any): ConnectionLocation {
        return new ConnectionLocation(map.Address, map.Interval, map.Legislation);
    }
}

export class ExternalDossier {
    constructor(
        public id: ExternalDossierId,
        public eventTimestamp: Timestamp,
        public mutation: string,
        public operatorDossierId?: string,
        public event?: string,
    ) { }

    public static readObject(map: any): ExternalDossier {
        return new ExternalDossier(
            map.Id,
            Timestamp.readObject(map['EventTimestamp']),
            map.Mutation,
            map.OperatorDossierId,
            map.Event,
        );
    }
}

export class ConnectionProfile {
    ProfileId: string;
    Interval: Interval;

    public static readObject(map: any): ConnectionProfile {
        const mp = map as ConnectionProfile;
        return mp;
    }
}

export class Cluster {
    constructor(
        public clusterName: string,
        public clusterType: string,
        public clusterTypeName: string,
        public connectionCluster: ConnectionIdWithInterval[],
        public tenantName?: string,
        public tenant?: Tenant,
    ) { }
    public static readObject(map: any): Cluster {
        const connectionCluster = map.ConnectionCluster.map(cc => ObjectParser.readObject('ConnectionIdWithInterval', cc));
        return new Cluster(
            map.ClusterName,
            map.ClusterType,
            map.ClusterTypeName,
            connectionCluster,
            map.TenantName,
            map.Tenant,
        );
    }
}

export interface ICluster extends Cluster {
    isActive: boolean,
}

export class ConnectionIdWithInterval {
    constructor(
        public connectionId: ClusterId,
        public from: Timestamp,
        public until?: Timestamp,
    ) { }
    public static readObject(map: any): ConnectionIdWithInterval {

        return new ConnectionIdWithInterval(
            map.ConnectionId,
            Timestamp.readObject(map.From),
            Timestamp.readObject(map.Until)
        );
    }
}

export class ConnectionCluster {
    constructor(
        public clusterId: ClusterId,
        public interval: Interval
    ) { }
    public static readObject(map: any): ConnectionCluster {
        return new ConnectionCluster(
            map.ClusterId,
            map.Interval
        );
    }
}

export class ClusterId {
    constructor(
        public name: string,
        public clusterType: string,
        public tenant?: string
    ) { }
    public static readObject(map: any): ClusterId {
        return new ClusterId(map.Name, map.ClusterType, map.Tenant);
    }
}

export class TransactionDossierStepId {
    constructor(
        public mutationReason: MeasurementMutationReason,
        public transactionDossierId: TransactionDossierStepId
    ) { }
    public static readObject(map: any): TransactionDossierStepId {
        return new TransactionDossierStepId(map.MutationReason, map.TransactionDossierId);
    }
}

export class ConnectionContact {
    public FromDb: Timestamp;
    public UntilDb: Timestamp;
    public Contact: ConnectionContactContact;

    public static readObject(map: any): ConnectionContact {
        var mp = map as ConnectionContact;
        mp.FromDb = Timestamp.readObject(map["FromDb"]);
        mp.UntilDb = Timestamp.readObject(map["ModifiedUntilDbTimestamp"]);
        mp.Contact = ConnectionContactContact.readObject(map["Contact"]);
        return mp;
    }
}

export class ConnectionContactContact {
    public Id: string;
    public Name: string;
    public PersonalName: string;
    public PhoneNumberLandLine: string;
    public PhoneNumberMobile: string;
    public Email: string;
    public Address: ConnectionContactContactAddress;
    public ConnectionContacts: any;

    public static readObject(map: any): ConnectionContactContact {
        var mp = map as ConnectionContactContact;
        mp.Address = ConnectionContactContactAddress.readObject(map["Address"]);
        return mp;
    }
}
export class ConnectionContactContactAddress {
    public Street: string;
    public LocationOnStreet: string;
    public Postcode: string;
    public City: string;

    public static readObject(map: any): ConnectionContactContactAddress {
        var mp = map as ConnectionContactContactAddress;
        return mp;
    }
}
export class ConnectionEstimatedAnnualProsumption {
    public Tariff?: Tariff;
    public Interval?: Interval;
    public Prosumption?: Prosumption;

    public static readObject(map: any): ConnectionEstimatedAnnualProsumption {
        return map as ConnectionEstimatedAnnualProsumption;
    }
}



export class Contact {
}
export class Location {
}

export class CustomerLocation {
}
export class ConnectionSupplyType {
    constructor(
        public supplyType: SupplyType,
        public Interval: Interval,
    ) { }
    public toString = (): string => {
        return JSON.stringify(this);
    }
    public static readObject(map: any): ConnectionSupplyType {
        return new ConnectionSupplyType(map.SupplyType, map.Interval);
    }
}

export class ConnectionMeter {
    constructor(
        public Meter: Meter,
        public Interval: Interval,
        public TelemetryIsOperational: boolean,
        public ExternalId
    ) { }

    public static readObject(map: any): ConnectionMeter {
        return new ConnectionMeter(map.Meter, map.Interval, map.TelemetryIsOperational, map.ExternalId);
    }

    public toString = (): string => JSON.stringify(this);
}

export class MeasurementDisplay {
    SupplyType: SupplyType;
    Tariff: Tariff;
    MultiplicationFactor: Number;
    Digits: Number;
    ExternalId: string;

    public static readObject(map: any): MeasurementDisplay {
        var mp = map as MeasurementDisplay;
        mp.SupplyType = SupplyType[map["SupplyType"]] as unknown as SupplyType
        if (map['Tariff'] == "Low") {
            mp.Tariff = Tariff.low;
        }
        else if (map['Tariff'] == "Normal") {
            mp.Tariff = Tariff.normal;
        }

        return mp;
    }
}
export class ConnectionGridOperator {
    constructor (
        public gridOperatorId: any,
        public interval?: Interval,
    ) {}
    public static readObject(map: any): ConnectionGridOperator {
        return new ConnectionGridOperator(map.GridOperatorId, map.Interval);
    }
    public toString = (): string => JSON.stringify(this);
}
export class ConnectionGridArea {
    constructor(
        public gridAreaId: any,
        public interval?: Interval
    ) {}
    public static readObject(map: any): ConnectionGridArea {  
      return new ConnectionGridArea(map.GridAreaId, map.Interval);
    }
    public toString = (): string => JSON.stringify(this);
}
export class ConnectionTariffSwitche {
    Interval: Interval;
    TariffSwitch: string;
}
export class ConnectionConnectionCapacitie {
    constructor(
        public ConnectionCapacity: string,
        public ExternalId: string,
        public Interval: Interval
    ) {}

    public static readObject(map: any): ConnectionConnectionCapacitie {
        return map as ConnectionConnectionCapacitie;
    }
}
// export class ConnectionSupplyType {
//     Interval :Interval;
//     SupplyType :string;
// }
export class ConnectionPhysicalStates {
    Interval: ConnectionPhysicalState
}
export class ConnectionPhysicalState {
    Interval: Interval;
    PhysicalState: string;
}
export class CustomerConnection {
    // public Customer :any;
    // public Interval :Interval;
    constructor(
        public Customer: any,
        public Interval: Interval,
    ) { }
    public static readObject(map: any): CustomerConnection {
        return new CustomerConnection(map.Customer, map.Interval)
    }
}
export enum ProductType {
    Electricity = "Electricity",
    Gas = "Gas",
}

export enum MarketType {
    Apx = "Apx",
    Leba = "Leba",
}

export enum Legislation {
    NL = "NL",
    BE = "BE",
}
export enum Granularity {
    QuarterHour = "QuarterHour",
    Hour = "Hour",
    Day = "Day",
    Week = "Week",
    Month = "Month",
    QuarterYear = "QuarterYear",
    Year = "Year",
}
export class ConnectionForecast {
    ConnectionId: string;
    Interval: Interval;
    ConsumptionMedian: number;
    ProductionMedian: number;
    CustomerId?: string;
    ForecastDay?: ForecastDay;
}

export enum ForecastDay {
    IDM,
    DAM,
    DAM2,
    DAM3,
    DAM4,
    DAM5,
}

export enum Process {
    MeasurementCollection,
    MeasurementCollectionStartOfDay,
    MeasurementCollectionSmallestGranularity,
    ConsolidationCorrection,
    ConsolidationDistribution,
    ConsolidationLargeCapacityDistribution,
    ConsolidationDataCompletion,
    ConsolidationEstimation,
    AggregationConsumption,
    AggregationAllocation,
    AggregationReconciled,
    MeasurementCorrection,
    MeasurementEstimation,
    MeasurementDistribution,
    ConsumptionMoneyDetermination,
    ConsumptionAggregation,
    AreaTemperatureCollection,
    MeasurementCollectionRequest,
    MeasurementCollectionResponse,
    MeasurementCollectionP4Registration,
    MeasurementCollectionMeterReading,
    MeasurementCommunication,
    MeasurementsToSubmitDetermination,
    PriceCollectionApx,
    PriceCollectionLeba,
    AllocationBySupplierCalculation,
    ReconciliationBySupplierEstimation,
    ForecastCollectionDexterCluster,
    ForecastCollectionDexterConnection

}
export class Customer {
    public Id: Number;
    public Name: string;
    public toString = (): string => {
        return JSON.stringify(this);
    }
}
export class InvoiceContact extends Contact {
}
export class PurchaseContact {
}
export class AuthorizedRepresentative {
}
export class ConnectionAllocationMethod {
    constructor(
        public interval: Interval,
        public allocationMethod: AllocationType, // AllocationType: Profile or Telemetric Enums
    ) { }
    public static readObject(map: any): ConnectionAllocationMethod {
        return new ConnectionAllocationMethod(map.Interval, map.AllocationMethod);
    }
}

export class Meter {

    constructor(
        public Id: string,
        public CorrectForEnergyPerVolumeDueToTemperature: boolean,
        public IsTelemeter: boolean,
        public MeasurementDisplays: MeasurementDisplay[]
    ) { }

    public static readObject(map: any): Meter {
        //without using _obj this property remains undefined somewhere in the fill/read object methods this property wont be mapped
        return new Meter(map.Id, map._obj === undefined || map._obj === null ? false: map._obj.CorrectForEnergyPerVolumeDueToTemperature, map.IsTelemeter, map.MeasurementDisplays);
    }

    public toString = (): string => JSON.stringify(this);
}
export class TariffSwitch {
}

export class ConnectionCapacity {
}
export class EstimatedAnnualProsumption {
}
export class Profile {
    ProfileId: string;
    Interval: Interval;
}
export enum SupplyType {
    Production = "Production",
    Consumption = "Consumption",
    Prosumption = "Prosumption"
}
export class PhysicalState {
}
export enum Tenant {
    NieuweStroom = "NieuweStroom",
    EasyEnergy = "EasyEnergy"
}

export enum MeasurementReadingMethod {
    Invalid = "Invalid",
    Agreed = "Agreed",
    Calculated = "Calculated",
    CalculatedByBalanceSupplier = "CalculatedByBalanceSupplier",
    Estimated = "Estimated",
    Recorded = "Recorded",
    RecordedByCustomer = "RecordedByCustomer",
    RecordedByBalanceSupplier = "RecordedByBalanceSupplier",
}

export enum AllocationType {
    Profile = "Profile",
    Telemetric = "Telemetric",
}

export enum MeasurementMutationReason {
    invalid = "invalid",
    BULKPV = "BULKPV",
    CONNACT = "CONNACT",
    CONNCHG = "CONNCHG",
    CONNCRE = "CONNCRE",
    CONNDACT = "CONNDACT",
    CONNEND = "CONNEND",
    CONNUPD = "CONNUPD",
    CONSMTR = "CONSMTR",
    DISPUTE = "DISPUTE",
    DSTRCONN = "DSTRCONN",
    DSTRMSTR = "DSTRMSTR",
    ENDOFMV = "ENDOFMV",
    EOSUPPLY = "EOSUPPLY",
    HISTMTR = "HISTMTR",
    MOVEIN = "MOVEIN",
    MOVEOUT = "MOVEOUT",
    MTREND = "MTREND",
    MTRINST = "MTRINST",
    MTRUPD = "MTRUPD",
    NAMECHG = "NAMECHG",
    NMCRSCMP = "NMCRSCMP",
    PERMTR = "PERMTR",  // Periodieke meterstand
    PHYSMTR = "PHYSMTR",
    RESCOMP = "RESCOMP",
    SWITCHLV = "SWITCHLV",
    SWITCHMV = "SWITCHMV",
    SWITCHPV = "SWITCHPV",
    SWTCHUPD = "SWTCHUPD",
    ALLMTCHG = "ALLMTCHG",
    MONTHMTR = "MONTHMTR",
    INDHSE = "INDHSE",
    INDHSH = "INDHSH",
    MDMD = "MDMD",
    MDPPMD = "MDPPMD",
    ONRQST = "ONRQST",
    CONTRCAN = "CONTRCAN",
    CONTRDAT = "CONTRDAT",
    CONTRMOV = "CONTRMOV",
    CERCANCELLATION = "CERCANCELLATION",
    MOVETOT = "MOVETOT", // Custom Frontend enum
    SWITCHTOT = "SWITCHTOT", // Custom Frontend enum
}

export class CustomerConnectionSendMeasurementCommunication {
    constructor(
        public connectionId: string,
        public mmcEnabled: boolean,
        public blockedUntil?: Moment
    ) { }

    public static readObject(map: any): CustomerConnectionSendMeasurementCommunication {
        // only way to read a boolean from the map, for initialization
        let boolean = map['_obj'].MMCEnabled;
        const blockedUntil = map['_obj'].BlockedUntil;
        return new CustomerConnectionSendMeasurementCommunication(
            map.ConnectionId,
            boolean,
            blockedUntil
        )
    }
}

export enum Enums {
    MeasurementSource = 'MeasurementSource',
    MeasurementMutationReason = "MeasurementMutationReason",
    MeasurementReadingMethod = "MeasurementReadingMethod",
    Granularity = "Granularity",
    Process = "Process",
    IssueType = "IssueType",
    Tenant = "Tenant",
    MutationState = "MutationState",
    MutationReason = "MutationReason",
    DataTransferMessageType = "DataTransferMessageType",
    DataTransferMessageDirection = "DataTransferMessageDirection",
    ClusterType = "ClusterType"
}

export class MeasurementMutationReasonClass {
    public invalid: MeasurementMutationReason;
    public BULKPV: MeasurementMutationReason;
    public CONNACT: MeasurementMutationReason;
    public CONNCHG: MeasurementMutationReason;
    public CONNCRE: MeasurementMutationReason;
    public CONNDACT: MeasurementMutationReason;
    public CONNEND: MeasurementMutationReason;
    public CONNUPD: MeasurementMutationReason;
    public CONSMTR: MeasurementMutationReason;
    public DISPUTE: MeasurementMutationReason;
    public DSTRCONN: MeasurementMutationReason;
    public DSTRMSTR: MeasurementMutationReason;
    public ENDOFMV: MeasurementMutationReason;
    public EOSUPPLY: MeasurementMutationReason;
    public HISTMTR: MeasurementMutationReason;
    public MOVEIN: MeasurementMutationReason;
    public MOVEOUT: MeasurementMutationReason;
    public MTREND: MeasurementMutationReason;
    public MTRINST: MeasurementMutationReason;
    public MTRUPD: MeasurementMutationReason;
    public NAMECHG: MeasurementMutationReason;
    public NMCRSCMP: MeasurementMutationReason;
    public PERMTR: MeasurementMutationReason;
    public PHYSMTR: MeasurementMutationReason;
    public RESCOMP: MeasurementMutationReason;
    public SWITCHLV: MeasurementMutationReason;
    public SWITCHMV: MeasurementMutationReason;
    public SWITCHPV: MeasurementMutationReason;
    public SWTCHUPD: MeasurementMutationReason;
    public ALLMTCHG: MeasurementMutationReason;
    public MONTHMTR: MeasurementMutationReason;
    public INDHSE: MeasurementMutationReason;
    public INDHSH: MeasurementMutationReason;
    public MDMD: MeasurementMutationReason;
    public MDPPMD: MeasurementMutationReason;
    public ONRQST: MeasurementMutationReason;
    public CONTRCAN: MeasurementMutationReason;
    public CONTRDAT: MeasurementMutationReason;
    public CONTRMOV: MeasurementMutationReason;
    public CERCANCELLATION: MeasurementMutationReason;

    public toString = (): string => {
        return JSON.stringify(this);
    }
}

export class Lock {
    public Id: string;
    public Name: string;
    public toString = (): string => {
        return JSON.stringify(this);
    }
}
export class Issue {
    public Id: string;
    public IssueType: string;
    public Subject: string;
    public ConnectionId: string;
    public Context?: any;
    public Tenant?: string;
    public From: Timestamp;
    public Until?: Timestamp;
    public Interval?: Interval;
    public ContextInterval?: Interval;
    public ContextFrom?: Timestamp;
    public ContextUntil?: Timestamp;
    public ModifiedTimestamp?: Timestamp;
    public archivedIssue: boolean;
    public Urgency: string;
    public Assignee: string;
    public CustomerId: Number;

    public toString = (): string => {
        return JSON.stringify(this);
    }
    public static readObject(map: any): Issue {
        var mp: Issue = map as Issue;


        if (typeof map['Context'] === 'object') {
            // It is an object for ConsolidationDataCompletion_ClosedConsumptionQuantitiesMismatch > Quantities do not match. Has a message.
            if (mp.Context.Message != undefined) {
                mp.Context = mp.Context.Message
            } else {
                // returns [object object]
            }
        }

        mp.archivedIssue = false;
        if (map['Until']) {
            mp.Until = map["Until"] == null ? null : Timestamp.readObject(map["Until"]);
            mp.archivedIssue = map["Until"] == null ? false : true;
        }
        mp.From = map["From"] == null ? null : Timestamp.readObject(map["From"]);
        let interval;
        if (map['Interval']) {
            interval = map["Interval"] == null ? null : map.Interval;
        }

        let contextInterval;
        if (map['ContextInterval']) {
            contextInterval = Interval.readObject(map["ContextInterval"]);
        }
        mp.ContextFrom = contextInterval.From == undefined ? null : contextInterval.From;
        mp.ContextUntil = contextInterval.Until == undefined ? null : contextInterval.Until;

        let returnValue = new Issue();
        returnValue.Id = mp.Id;
        returnValue.IssueType = mp.IssueType;
        returnValue.Subject = mp.Subject;
        returnValue.ConnectionId = mp.ConnectionId;
        returnValue.Context = mp.Context;
        returnValue.Tenant = mp.Tenant;
        returnValue.From = mp.From;
        returnValue.Until = mp.Until;
        returnValue.Interval = interval;
        returnValue.ContextInterval = contextInterval;
        returnValue.ContextFrom = mp.ContextFrom;
        returnValue.ContextUntil = mp.ContextUntil;
        returnValue.ModifiedTimestamp = mp.ModifiedTimestamp;
        returnValue.archivedIssue = mp.archivedIssue;
        returnValue.Assignee = mp.Assignee;
        returnValue.Urgency = mp.Urgency;
        returnValue.CustomerId = mp.CustomerId;

        return returnValue;
    }
}
export class IssueCountPerIssueTypeAndSubject {
    IssueType: string;
    Subject: string;
    Count: number;
}

export class IssueWithConnection {
    public toString = (): string => {
        return JSON.stringify(this);
    }
}
export enum MeasurementSource {
    Telemeter = "Telemeter",
    DataCorrector = "DataCorrector",
    DataSplitter = "DataSplitter",
    DataEstimator = "DataEstimator",
    GridOperator = "GridOperator",
    Supplier = "Supplier",
    Customer = "Customer"
}
export enum Tariff {
    normal = "Normal",
    low = "Low",
}

export class LocalDate {
    public LocalDate: string;

    constructor(localDate: string) {
        this.LocalDate = localDate;
    }
    public valueOf = (): number => moment(this.LocalDate).valueOf();
    public moment = (): moment.Moment => moment(this.LocalDate);
    public toString = (): string => {
        return JSON.stringify(this);
    }
}

// Consumptions and Measurement Prosumptions/Quantities

export class Prosumption {

    constructor(
        public Consumption: number,
        public Production: number,
    ) { }

    public static readObject(map: any): Prosumption {
        // TypeScript is somewhat funkey with 0's and doesn't initialize.
        // Check Albert on 2020-01-01 and 2020-02-19 and use get historically, gets nulls and 0's
        // _obj was use for testing and works, it's likely somewhere in the conversion of the API object using readObject.

        // A nullcheck is done, if no prosumption is returned, the _obj is not present, so then use the empty map.Consumption
        let cons = map["_obj"] != undefined ? map["_obj"].Consumption : map.Consumption
        let prod = map["_obj"] != undefined ? map["_obj"].Production : map.Production

        return new Prosumption(cons, prod);
    }
}

export class QuantityWithCost {
    public QuantityMeasured?: number // Measured, without correction and factors of temperature
    public Quantity?: number // The factor quantity that should be shown
    public Cost?: number

    constructor(quantityMeasured?: number, quantity?: number, cost?: number) {
        this.QuantityMeasured = quantityMeasured;
        this.Quantity = quantity;
        this.Cost = cost;
    }
}

export class Quantities {
    public Quantity: number
    public Cost: number
}

export class ProsumptionQuantitiesWithMeasured {
    public Consumption?: QuantityWithCost
    public Production?: QuantityWithCost

    constructor(consumption?: QuantityWithCost, production?: QuantityWithCost) {
        this.Consumption = consumption;
        this.Production = production;
    }
}

///////

export class ConsumptionPerGranularityInterval {
    public Id: ConsumptionPerGranularityIntervalId;
    public ModifiedTimestamp: Timestamp;
    public MutationState: string;
    public ProductType: ProductType;
    public Prosumption: ProsumptionQuantitiesWithMeasured;
    public Tenant?: Tenant

    public static readObject(map: any): ConsumptionPerGranularityInterval {
        var mp = map as ConsumptionPerGranularityInterval;
        mp.Id = ConsumptionPerGranularityIntervalId.readObject(map["Id"]);
        mp.ModifiedTimestamp = Timestamp.readObject(map["ModifiedTimestamp"]);
        return mp;
    }
}
export class ConsumptionPerGranularityIntervalId {
    public ConnectionId: string;
    public CustomerId?: Number;
    public From: Timestamp;
    public Granularity: Granularity
    public Interval: DateRange;
    public Tariff?: Tariff;



    public static readObject(map: any): ConsumptionPerGranularityIntervalId {
        var mp = map as ConsumptionPerGranularityIntervalId;
        // mp.Tariff = StringToEnum(Tariff, (map["Tariff"]));
        if (map['Tariff'] == "low") {
            mp.Tariff = Tariff.low;
        }
        else if (map['Tariff'] == "normal") {
            mp.Tariff = Tariff.normal;
        }
        if (mp.Tariff != undefined) {
            let tarif = StringToEnum(Tariff, mp.Tariff);
        }
        mp.From = Timestamp.readObject(map["From"]);
        mp.Interval = DateRange.readObject(map["Interval"]);
        return mp;
    }
}

export class ConsumptionPerClosedDateRange {
    public Id: ConsumptionPerClosedDateRangeId;
    public ClosedDateRange: DateRange
    public ProductType: ProductType
    public Tenant?: Tenant
    public CustomerId?: number
    public Prosumption: ProsumptionQuantitiesWithMeasured
    public MutationState: string
    public ModifiedTimestamp?: Timestamp

    constructor(
        id: ConsumptionPerClosedDateRangeId,
        closedDateRange: DateRange,
        productType: ProductType,
        mutationState: string,
        tenant?: Tenant,
        customerId?: number,
        prosumption?: ProsumptionQuantitiesWithMeasured,
        modifiedTimestamp?: Timestamp,
    ) {
        this.Id = ConsumptionPerClosedDateRangeId.readObject(id);
        this.ClosedDateRange = closedDateRange;
        this.ProductType = productType;
        this.MutationState = mutationState;
        this.Tenant = tenant;
        this.CustomerId = customerId;
        this.Prosumption = prosumption;
        this.ModifiedTimestamp = modifiedTimestamp
    }

    // public static readObject(map: any): MeasurementIdOrig {
    //     return new ConsumptionPerClosedDateRange(
    //         // // .ne which MeasurementId we need
    //         // map.ConnectionId,
    //         // map.MeterId,
    //         // new LocalDate(map.Id.Date),
    //         // map.MeasurementSource,
    //         // map.Timestamp = Timestamp.readObject(map.Timestamp),
    //         // map.Tariff,
    //         // // map.Id.ModifiedTimestamp = Timestamp.readObject(map.ModifiedTimestamp)
    //         // // Other (already defined) variabels
    //     );
    // }

    public static readObject(map: any): ConsumptionPerClosedDateRange {
        var mp = map as ConsumptionPerClosedDateRange;
        mp.Id = ConsumptionPerClosedDateRangeId.readObject(map["Id"]);
        mp.ClosedDateRange = DateRange.readObject(map["ClosedDateRange"]);
        mp.ModifiedTimestamp = Timestamp.readObject(map["ModifiedTimestamp"]);
        return mp;
    }
}
export class ConsumptionPerClosedDateRangeId {
    public ConnectionId: string
    public From: LocalDate;
    public Tariff?: Tariff;
    public ModifiedTimestamp?: Timestamp;

    constructor(
        connectionId: string,
        from: string,
        tariff?: Tariff,
        modifiedTimestamp?,
    ) {
        this.ConnectionId = connectionId;
        this.From = new LocalDate(from);
        this.Tariff = tariff;
        this.ModifiedTimestamp = modifiedTimestamp;
    }

    public static readObject(map: any): ConsumptionPerClosedDateRangeId {
        return new ConsumptionPerClosedDateRangeId(
            map.ConnectionId,
            map.From,
            map.Tariff,
            map.ModifiedTimestamp // Timestamp.readObject(map.ModifiedTimestamp) (but we require it for deletion)
        );
    }
}

export class ByTariff<T> {
    constructor(
        public None: T[],
        public Normal: T[],
        public Low: T[],
    ) { };

    any(): T {
        return (this.None[0] || this.Normal[0] || this.Low[0])
    }
}

export class MeasurementsGroupedByTariff {
    constructor(
        public measurementsByTariff: ByTariff<Measurement>,
    ) { }

    public static readObject(map: any): MeasurementsGroupedByTariff {
        let byTariff = new ByTariff<Measurement>(
            map.filter(m => m.Id.Tariff === null),
            map.filter(m => m.Id.Tariff === Tariff.normal),
            map.filter(m => m.Id.Tariff === Tariff.low)
        );
        return new MeasurementsGroupedByTariff(
            byTariff,
        );
    }
}

export class ConsumptionWithMeasurementsInterval {

    constructor(
        public consumption: ConsumptionPerGranularityInterval[] | ConsumptionPerClosedDateRange[],
        public interval: Interval,
        public measurement: Measurement[],
        public measurementsByTariff?: ByTariff<Measurement | any>,
        public consumptionByTariff?: ByTariff<ConsumptionPerGranularityInterval[] | ConsumptionPerClosedDateRange>, // a dumb usage does not have bytariffs lists.
    ) {
    }

    public static readObject(map: any): ConsumptionWithMeasurementsInterval {
        const interval = Interval.readObject(map.Interval);
        const consumptions = map.Consumption.map(c => ConsumptionPerGranularityInterval.readObject(c));
        const measurements = map.Measurements.map(m => Measurement.readObject(m));

        return this.create(interval, consumptions, measurements);
    }

    public static create(
        interval: Interval,
        consumptions: any,
        measurements: Measurement[],//MeasurementHistorically[],
    ) {
        const consumptionsByTariff = new ByTariff<ConsumptionPerGranularityInterval[] | ConsumptionPerClosedDateRange>(
            consumptions.filter(c => c.Id.Tariff === null || undefined),
            consumptions.filter(c => c.Id.Tariff === Tariff.normal),
            consumptions.filter(c => c.Id.Tariff === Tariff.low)
        );

        const measurementsByTariff = new ByTariff<Measurement>(
            measurements.filter(m => m.Id.Tariff === null),
            measurements.filter(m => m.Id.Tariff === Tariff.normal),
            measurements.filter(m => m.Id.Tariff === Tariff.low)
        );

        return new ConsumptionWithMeasurementsInterval(
            consumptions,
            interval,
            measurements,
            measurementsByTariff,
            consumptionsByTariff
        );
    }
}

export class MeasurementId {
    constructor(
        public ConnectionId: string,
        public MeterId: string,
        public Date: LocalDate,
        public MeasurementSource: MeasurementSource,
        public Timestamp?: Timestamp,
        public Tariff?: Tariff,
        public ModifiedTimestamp?: Timestamp
    ) { }

    public static readObject(map: any): MeasurementId {
        const date = new LocalDate(map.Date);
        const timestamp = Timestamp.readObject(map.Timestamp);
        const modifiedTimestamp = Timestamp.readObject(map.ModifiedTimestamp);

        return new MeasurementId(
            map.ConnectionId,
            map.MeterId,
            date,
            map.MeasurementSource,
            timestamp,
            map.Tariff,
            modifiedTimestamp,
        );
    }
}

export class Measurement {
    constructor(
        public Id: MeasurementId,
        public ProductType: ProductType,
        public Tenant: string,
        public CustomerId: number,
        public SubmittedToGridOperator: boolean,
        public Deleted: boolean, // from API
        public Prosumption?: Prosumption,
        public ModifiedTimestamp?: Timestamp,
    ) { }

    public static readObject(map: any): Measurement {

        const modifiedTimestamp = Timestamp.readObject(map.ModifiedTimestamp);

        return new Measurement(
            MeasurementId.readObject(map.Id),
            map.ProductType,
            map.Tenant,
            map.CustomerId,
            map.SubmittedToGridOperator === true,
            map.Deleted === true,
            map.Prosumption,
            modifiedTimestamp,
        );
    }
}

export class MCMeasurement {
    constructor(
        public Normal: Measurement,
        public Low?: Measurement,
    ) { }
    public static readObject(map: any): MCMeasurement {
        let lowMeasurement: Measurement;
        if (map.Low) {
            lowMeasurement = new Measurement(
                MeasurementId.readObject(map.Low.Id),
                // Other (already defined) variabels
                map.Low.ProductType,
                map.Low.Tenant,
                map.Low.CustomerId,
                map.Low.SubmittedToGridOperator === true ? true : false,
                // transform using the readobject here.
                map.Low.Deleted === true ? true : false, //map.Deleted, //
                map.Low.Prosumption,
                map.Low.ModifiedTimestamp,
            );
        }
        const normalMeasurement: Measurement = new Measurement(
            MeasurementId.readObject(map.Normal.Id),
            // Other (already defined) variabels
            map.Normal.ProductType,
            map.Normal.Tenant,
            map.Normal.CustomerId,
            map.Normal.SubmittedToGridOperator === true ? true : false,
            // transform using the readobject here.
            map.Normal.Deleted === true ? true : false, //map.Deleted, //
            map.Normal.Prosumption,
            map.Normal.ModifiedTimestamp,
        );

        return new MCMeasurement(
            normalMeasurement,
            lowMeasurement
        );
    }

}

export class Value {
    public Value: number;
    public Money: number;
}
export class ProsumptionValue {
    public Consumption: Value;
    public Production: Value;
}
export class ConsumptionByTariff {
    public ConnectionId: string;
    public Customerid: Number;
    public Interval: Interval;
    public ProductType: ProductType;
    public Tenant: Tenant;
    public Low: ProsumptionValue;
    public Normal: ProsumptionValue;
    public static readObject(map: any): ConsumptionByTariff {
        var mp = map as ConsumptionByTariff;
        mp.Interval = Interval.readObject(map["Interval"]);
        return mp;
    }

    public toString = (): string => {
        return JSON.stringify(this);
    }
}

export class EnumDescription {
    constructor(
        public Enum: string,
        public Value: string,
        public Translation: string,
        public Description: string,
        public IntValue?: number,
    ) { }

    public static readObject(map: any): EnumDescription {
        return new EnumDescription(
            map.Enum,
            map.Value,
            map.Translation,
            map.Description,
            map["_obj"]?.IntValue === 0 ? 0 : map?.IntValue,
        );
    }
}

export class ProcessStatistics {
    public AdditionalStatistics: AdditionalStatistics;
    public FailedElementCount: Number;
    public Id: ProcessStatisticsId;
    public ModifiedTimestamp: Timestamp
    public ProcessedElementCount: Number;
    public ProgressedElementCount: Number;
    public ProgressedUntilMax?: Timestamp
    public ProgressedUntilMin?: Timestamp
    public RetriedElementCount: Number;
    public RunDuration: String; // must change to time. D:HH:MM:SS.MSMSMSMS
    public SkippedElementCount: Number;
    public TotalElementCount: Number;
    public TotalProgressDuration: String; // must change to time.

    public static readObject(map: any): ProcessStatistics {
        var mp: ProcessStatistics = map as ProcessStatistics;

        mp.ModifiedTimestamp = map.ModifiedTimestamp;
        // These fields are not always used. Therefor nullable / optional -> ? added
        mp.ProgressedUntilMax = Timestamp.readObject(map["ProgressedUntilMax"]);
        mp.ProgressedUntilMin = Timestamp.readObject(map["ProgressedUntilMin"]);

        mp.Id = ProcessStatisticsId.readObject(map["Id"]);

        let returnValue = new ProcessStatistics();
        returnValue.AdditionalStatistics = mp.AdditionalStatistics;
        returnValue.FailedElementCount = mp.FailedElementCount;
        returnValue.Id = mp.Id;
        returnValue.ModifiedTimestamp = mp.ModifiedTimestamp;
        returnValue.ProcessedElementCount = mp.ProcessedElementCount;
        returnValue.ProgressedElementCount = mp.ProgressedElementCount;
        returnValue.ProgressedUntilMax = mp.ProgressedUntilMax;
        returnValue.ProgressedUntilMin = mp.ProgressedUntilMin;
        returnValue.RetriedElementCount = mp.RetriedElementCount;
        returnValue.RunDuration = mp.RunDuration;
        returnValue.SkippedElementCount = mp.SkippedElementCount;
        returnValue.TotalElementCount = mp.TotalElementCount;
        returnValue.TotalProgressDuration = mp.TotalProgressDuration;
        return returnValue;
    }
    public exporttoString = (): string => {
        return JSON.stringify(this);
    }
}

export class ProcessProgress {

    deleted? :boolean;

    constructor(
        public ConnectionId: string,
        public Process: Process,
        public ModifiedTimestamp: Timestamp,
        public ProgressedUntil: Timestamp,
        public Context: any,
        public Tenant: Tenant,
    ) { }
    public static readObject(map: any): ProcessProgress {
        let progUntil: Timestamp = map.ProgressedDataUntil != null ? Timestamp.readObject(map.ProgressedDataUntil) : null;
        let isValidCheck = progUntil.moment.isValid();
        if (!isValidCheck) { console.warn('This ProcessProgress Untill timestamp is invalid ', map) }

        return new ProcessProgress(
            map.ConnectionId,
            map.Process,
            map.ModifiedTimestamp,
            map.ProgressedUntil = isValidCheck ? progUntil : null,
            map.Context,
            map.Tenant
        );
    }
}

export class ProcessStatisticsId {
    constructor(
        public appInstanceDescription: String,
        public appInstancePartition: String,
        public process: String,
        public ranFrom: Timestamp,
        public tenant: String,
    ) { }


    public static readObject(map: any): ProcessStatisticsId {
        return new ProcessStatisticsId(map.AppInstanceDescription, map.AppInstancePartition, map.Process, Timestamp.readObject(map["RanFrom"]), map.Tenant);
    }
}

export class AdditionalStatistics {
    public Exceptions: any;
    public LongestRuns: any;
}

export class MeasurementCommunicationType {
    public annualInvoiceRequest: string;
    public transactionDossierStepRequest: string;
    public periodicRequest: string;
    public telemetryNotOperationalRequest: string;
    public supplierRequest: string;
}

export class MeasurementCommunication {
    constructor(
        public _obj: any,
        public AuthenticationToken: string,
        public Contact: string,
        public ContactMeasurementCommunicationId: ContactMeasurementCommunicationId,
        public EventDate: LocalDate,
        public MeasurementCommunicationMeasurements: any, // Now list of MeasurementsIds, how do we do that.
        public Meter: string,
        public ReminderTimestamp: Timestamp,
        public BlockedSince: Timestamp

    ) { }

    public static readObject(map: any): MeasurementCommunication {
        return new MeasurementCommunication(map, map.AuthenticationToken, map.Contact, map.ContactMeasurementCommunicationId, new LocalDate(map.EventDate), map.MeasurementCommunicationMeasurements, map.Meter, Timestamp.readObject(map.ReminderTimestamp), Timestamp.readObject(map.BlockedSince));
    }
}
export class ContactMeasurementCommunicationId {
    constructor(
        public MeasurementCommunicationType: MeasurementCommunicationType,
        public ContactId: string,
        public MeterId: string,
        public Timestamp: Timestamp,
    ) { }

    public static readObject(map: any): ContactMeasurementCommunicationId {
        return new ContactMeasurementCommunicationId(map.MeasurementCommunicationType, map.ContactId, map.MeterId, map.Timestamp);
    }
}

export class IsEnum {
    public theEnum: string;
    public enumDescription?: EnumDescription;

    constructor(theEnumValue: string) {
        this.theEnum = theEnumValue;
    }
}
export class ContractStateId {
    constructor(
        public contractId: string,
        public connectionId: string,
        public contractMutationState: string) {
    }
    public static readObject(map: any): ContractStateId {
        return new ContractStateId(map.ContractId, map.ConnectionId, map.ContractMutationState);
    }
}
export class ContractState {
    constructor(
        public id: ContractStateId,
        public groupId: string,
        public interval: Interval,
        public isActive: boolean,
        public modifiedTimestamp: Timestamp,
        public blocked: Timestamp,
        public tenant: Tenant,
        public contractStateTransitions: ContractStateTransition[],
        public isDownloadWelcomeLetterEnabled: boolean,
        public isDownloadStartDeliveryLetterEnabled: boolean,
        public isResendWelcomeLetterEnabled: boolean,
        public context?: any,
        public connectionContractStart?: LocalDate,
        public connectionContractEnd?: LocalDate,
        public switchNotificationDate?: LocalDate,
    ) {
    }
    public static readObject(map: any): ContractState {
        // Sort the contractStateTransitions Active first.
        map.ContractStateTransitions.sort((a, b) => b.interval.From.valueOf() - a.interval.From.valueOf());

        return new ContractState(
            ObjectParser.readObject("ContractStateId", map.Id),
            map.GroupId,
            map.Interval,
            map.IsActive,
            map.ModifiedTimestamp,
            Timestamp.readObject(map.Blocked),
            StringToEnum(map.Tenant, "Tenant"),
            map.ContractStateTransitions,
            map.IsDownloadWelcomeLetterEnabled,
            map.IsDownloadStartDeliveryLetterEnabled,
            map.IsResendWelcomeLetterEnabled,
            map.Context,
            map.ConnectionContractStart,
            map.ConnectionContractEnd,
            map.SwitchNotificationDate
        );
    }
}

export enum ContractStateTransitionUrgency {
    Low = "Low",
    Medium = "Medium",
    High = "High"
}

export class ContractStateTransition {
    constructor(
        public contractStateTransitionType: string,
        public interval: Interval,
        public isActiveTask: boolean,
        public modifiedTimestamp: ModifiedTimestamp,
        public contractStateTransitionUrgency?: ContractStateTransitionUrgency,
        public context?: any,
        public assignee?: string,
        public remarks?: string) {
    }

    public static readObject(map: any): ContractStateTransition {
        // Determine if it is the active step (has no until), and mark it that using the boolean
        let isActiveTask = map.Interval.Until == null ? true : false;

        // Add urgency to the transitionType
        var urgency = ContractStateTransitionUrgency[map["ContractStateTransitionUrgency"]] as unknown as ContractStateTransitionUrgency;

        // Tranformation of a single ContractStateTransition
        // First implementation of a remark to list of objects with a timestamp
        if (map.Remarks != undefined) {
            // Filter enters
            let remark = map.Remarks.replace(/\n/ig, '^')
            let listOfcomments = remark.split('^');
            let commentListObjects = listOfcomments.map(comment => {
                let element = comment.split('|');
                let timeToMoment = element[0];
                // remove Europe/Amsterdam (+02) with slice and turn it in a moment, and a timestamp
                timeToMoment = moment(timeToMoment.slice((0), (timeToMoment.length - 23)));
                timeToMoment = new Timestamp(timeToMoment);

                return {
                    timestamp: timeToMoment,
                    author: element[1],
                    comment: element[2]
                }
            })
            map.Remarks = commentListObjects;
        }

        return new ContractStateTransition(map.ContractStateTransitionType, map.Interval, isActiveTask, map.ModifiedTimestamp, urgency, map.Context, map.Assignee, map.Remarks);
    }
}

export class ContractMutationStateWithContractStateTransitions {
    constructor(
        public ContractMutationState: string,
        public ContractStateTransitionTypes: string[]
    ) { }

    public static readObject(map: any): ContractMutationStateWithContractStateTransitions {
        return new ContractMutationStateWithContractStateTransitions(map.ContractMutationState, map.ContractStateTransitionTypes);
    }
}

export class ContractStateCountPerContractMutationStateAndContractStateTransitionType {
    constructor(
        public count: number,
        public contractMutationState: string,
        public contractStateTransitionTypeCounts: any,
    ) { }

    public static readObject(map: any): ContractStateCountPerContractMutationStateAndContractStateTransitionType {
        return new ContractStateCountPerContractMutationStateAndContractStateTransitionType(map.Count, map.ContractMutationState, map.ContractStateTransitionTypeCounts);
    }
}

export class ConnectionContract {
    constructor(
        public contractId: string,
        public customerId: string,
        public connectionId: string,
        public complex: boolean,
        public residental: boolean,
        public skipReflectionTime: boolean,
        public contractDate: Timestamp,
        public startDate: LocalDate,
        public endDate: LocalDate,
        public isSwitch: boolean,
        public switchNotificationDate: LocalDate,
        public productType: ProductType,
        public anualEstimatedProsumption: Prosumption,
        public modifiedTimestamp?: Timestamp
    ) {
    }

    public static readObject(map: any): ConnectionContract {
        return new ConnectionContract(
            map.ContractId,
            map.CustomerId,
            map.ConnectionId,
            map.Complex,
            map.Residental,
            map.SkipReflectionTime,
            Timestamp.readObject(map.ContractDate),
            new LocalDate(map.StartDate),
            new LocalDate(map.EndDate),
            map.Switch,
            new LocalDate(map.SwitchNotificationDate),
            StringToEnum(ProductType, map.ProductType as string),
            Prosumption.readObject(map.AnualEstimatedProsumption),
            map.ModifiedTimestamp
        );
    }
}
export class AllocationSavings {
    constructor(
        public Savings: number,
        public AllocationMethod: string
    ) { }

    public static readObject(map: any): AllocationSavings {
        return new AllocationSavings(map.Savings, map.AllocationMethod);
    }
}

export class AllocationMethodComparison {
    constructor(
        public Interval: Interval,
        public TelemetricAllocation: Quantities,
        public Allocation: Quantities,
        public Reconcilliation: Quantities,
        public ProfileAllocation: Quantities,
        public AllocationSavings: AllocationSavings,
        public ReconcilliationPrice: number
    ) { }

    public static readObject(map: any): AllocationMethodComparison {
        return new AllocationMethodComparison(map.Interval, map.TelemetricAllocation, map.Allocation, map.Reconcilliation, map.ProfileAllocation, map.AllocationSavings, map.ReconcilliationPrice);
    }
}

export class AcmReport {
    constructor(
        public runDate: Timestamp,
        public acmReportStatistics: AcmReportStatistic[],
    ) { }

    public static readObject(map: any): AcmReport {
        // let reportStatistics = Object.values(map['_obj'].AcmReportStatistics).map(list => ObjectParser.readArray('AcmReportStatistics', list));
        return new AcmReport(
            Timestamp.readObject(map.RunDate),
            map.AcmReportStatistics,
        )
    }
}

export class AcmReportStatistic {
    constructor(
        public creditPercentage: Number,
        public from: Timestamp,
        public invoiceType: string,   //InvoiceType
        public isLargeCapacity: boolean,
        public onTimePercentage: Number,
        public productType: ProductType,
        public total: Number,
        public totalCredit: Number,
        public totalOnTime: Number,
        public totalOpenInvoiceDeadlinesOfLast12Months: Number, // Achterstand (openstaande invoiceDeadlines) vd afgelopen 12 maanden
        public until: Timestamp,
        public mutationReason?: MeasurementMutationReason,
        public mutation?: string, //(gain movein, loss)
        public openInvoices?: InvoiceDeadline[],
        public missedDeadline?: InvoiceDeadline[],
        public closedInvoicesInValidRange?: InvoiceDeadline[]
    ) { }

    public static readObject(map: any): AcmReportStatistic {
        // Fix, since ReadArray does not handle empty values well.
        if (map.CreditPercentage == undefined) {
            map.CreditPercentage = 0;
        }
        if (map.isLargeCapacity == undefined) {
            map.isLargeCapacity = false;
        }
        if (map.OnTimePercentage == undefined) {
            map.OnTimePercentage = 0;
        }
        if (map.Total == undefined) {
            map.Total = 0;
        }
        if (map.TotalCredit == undefined) {
            map.TotalCredit = 0;
        }
        if (map.TotalOnTime == undefined) {
            map.TotalOnTime = 0;
        }
        if (map.TotalOpenInvoiceDeadlinesOfLast12Months == undefined) {
            map.TotalOpenInvoiceDeadlinesOfLast12Months = 0;
        }
        if (map.MutationReason == undefined) {
            map.MutationReason = "Anders";
        }
        if (map.ClosedInvoicesInValidRange == undefined) {
            map.ClosedInvoicesInValidRange = [];
        }

        return new AcmReportStatistic(
            map['_obj'].CreditPercentage,
            Timestamp.readObject(map.From),
            map.InvoiceType,
            map.isLargeCapacity,
            map['_obj'].OnTimePercentage,
            map.ProductType,
            map['_obj'].Total,
            map['_obj'].TotalCredit,
            map['_obj'].TotalOnTime,
            map['_obj'].TotalOpenInvoiceDeadlinesOfLast12Months,
            Timestamp.readObject(map.Until),
            map.MutationReason,
            map.Mutation,
            map.OpenInvoices,
            map.MissedDeadline,
            map.ClosedInvoicesInValidRange
        )
    }
}

export class AcmDeadlines {
    // If you want to mock this, you need to call: ObjectParser.readObject("AcmDeadlines", this.mockACM)
    // On a mockedReport => imported: import mockAcmJSON from 'src/assets/ns_invoicedeadline.json'; and used: public mockACM:any = mockAcmJSON;
    constructor(
        public runDate: any,
        public invoiceDeadlines: InvoiceDeadline[],
    ) { }

    public static readObject(map: any): AcmDeadlines {
        return new AcmDeadlines(
            Timestamp.readObject(map.RunDate),
            map.InvoiceDeadlines,
        )
    }
}

export class InvoiceDeadline {

    constructor(
        public actualInvoiceDateTime: Timestamp,
        public invoiceNumber: string,  // "2019-12-03T00:00:00+01 Europe/Amsterdam"
        public from: Timestamp, //validRange From
        public until: Timestamp, // validRange Until
        public connectionId: string,
        public customerId: string,
        public hasBeenCredited: boolean = false,
        public invoiceType: any, //InvoiceType
        public isLargeCapacity: boolean = false,
        public productType: ProductType,
        public mutationReason: any, //MeasurementMutationReason
        public urgency: string,
        public daysLeadTime?: number
    ) { }

    public static readObject(map: any): InvoiceDeadline {
        let leadtime;
        // Make moments to compare here
        if ((map.From != null || map.From != undefined)) {
            map.From = moment(map.From)
        }
        if ((map.Until != null || map.Until != undefined)) {
            map.Until = moment(map.Until)
        }

        if (map.ActualInvoiceDateTime != null) {
            // If we have an invoice date (so it's send), we make it a timestamp, and we can determine the leadtime
            map.ActualInvoiceDateTime = Timestamp.readObject(map.ActualInvoiceDateTime)
            leadtime = map.ActualInvoiceDateTime.moment.diff(map.From, 'days');
        }

        // Build in 'custom' Urgency based on time till ACM Deadline
        let urgency = 'Low'
        if ((map.Until != null || map.Until != undefined) && map.Until.isAfter()) {
            let daysToInvoice = map.Until.diff(moment(), 'days');
            if (daysToInvoice < 14) { urgency = "Low" }
            if (daysToInvoice < 7) { urgency = "Medium" }
            if (daysToInvoice < 4) { urgency = "High" }
        }

        return new InvoiceDeadline(
            map.ActualInvoiceDateTime,
            map.InvoiceNumber,
            Timestamp.readObject(map.From),
            Timestamp.readObject(map.Until),
            map.ConnectionId,
            map.CustomerId,
            map['_obj'].HasBeenCredited, // Why does this not read without _obj
            map.InvoiceType,
            map['_obj'].IsLargeCapacity,
            map.ProductType,
            map.MutationReason,
            urgency,
            leadtime
        )
    }
}

export class ParameterValue {
    constructor(
        public parameterValueName: string,
        public from: Timestamp,
        public value?: any,
        public tenant?: Tenant,
        public modifiedTimestamp?: Timestamp,
        public until?: Timestamp
    ) { }

    public static readObject(map: any): ParameterValue {
        return new ParameterValue(
            map.ParameterValueName,
            Timestamp.readObject(map.From),
            map.Value,
            map.Tenant,
            map.ModifiedTimestamp,
            Timestamp.readObject(map.Until),
        )
    }
}

export class PricePerInterval {
    constructor(
        public interval: Interval,
        public money: number,
    ) { }
    public static readObject(map: any): PricePerInterval {
        return new PricePerInterval(
            Interval.readObject(map.Interval),
            map.Money,
        )
    }
}
export class ImbalancePricePerInterval {
    constructor(
        public interval: Interval,
        public moneyShort?: number,
        public moneyLong?: number,
        // public unit?: string,
    ) { }
    public static readObject(map: any): ImbalancePricePerInterval {
        return new ImbalancePricePerInterval(
            Interval.readObject(map.Interval),
            map.MoneyShort,
            map.MoneyLong,
        )
    }
}

export class Purchase {
    constructor(
        public interval: Interval,
        public money?: number,
        public value?: number,
        // public unit?: string,
    ) {
        this.money = money / 1000000;
        this.value = Math.round(value / 1000);
    }
    public static readObject(map: any): Purchase {
        return new Purchase(
            Interval.readObject(map.Interval),
            map.Money,
            map.Value,
        )
    }
}

export class ConnectionBaseAsset {
    public longitude: number;
    public latidtude: number;
    public powerKwAc: number;
    public connectionId: string;

    constructor(longitude: number, latidtude: number, powerKwAc: number, connectionId: string) {
        this.longitude = longitude;
        this.latidtude = latidtude;
        this.powerKwAc = powerKwAc;
        this.connectionId = connectionId as string;
    }
}

export class ConnectionWindAsset extends ConnectionBaseAsset {
    constructor(
        longitude: number, latidtude: number, powerKwAc: number, connectionId: string, NumberOfTurbines?: number, TurbineType?: string, CutOutMs?: number, TurbineManufacturer?: string
    ) {
        super(longitude, latidtude, powerKwAc, connectionId)
    }
    public static readObject(map: any): ConnectionWindAsset {
        console.log(map)
        return new ConnectionWindAsset(
            map.Longitude,
            map.Latidtude,
            map.PowerKwAc,
            map.ConnectionId,
            map.NumberOfTurbines,
            map.TurbineType,
            map.CutOutMs,
            map.TurbineManufacturer,
        )
    }
}

export class MeasurementSourceDetail {

    constructor(
        public event: string,
        public measurementSourceMethod: string,
        public id: MeasurementId,
    ) {
    }
    public static readObject(map: any): MeasurementSourceDetail {
        return new MeasurementSourceDetail(
            map.Event,
            map.MeasurementSourceMethod,
            MeasurementId.readObject(map.Id)
        )
    }
}
