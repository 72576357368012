<div class="col-sm-12">
  <ng-container *ngIf="parentData">
    <div class="row">
      <div class="col-sm-12" [formGroup]="formDataGroup">
        <!--Header-->
        <table class="table-responsive table-front table-data-right">
          <thead>
            <th scope="col" class="cdk-visually-hidden">Allocation Progress Id</th>
            <th scope="col" class="cdk-visually-hidden">Correlatie Id</th>
            <th scope="col" class="cdk-visually-hidden">Bericht Id</th>
            <th scope="col" class="cdk-visually-hidden">Kenmerk</th>
            <th scope="col" class="col-3">EAN</th>
            <th scope="col" class="col-3">Proces maand</th>
            <th scope="col" class="col-2">CSH Correctie</th>
            <th scope="col"></th>
            <th scope="col" class="col-2">Gecorrigeerd</th>
            <th scope="col" class="col-1"></th>
          </thead>
          <tbody>
            <tr>
              <td class="cdk-visually-hidden allocationProgressId">{{parentData.id}}</td>
              <td class="cdk-visually-hidden senderCorrelationID">{{parentData.senderCorrelationID}}</td>
              <td class="cdk-visually-hidden technicalMessageId">{{parentData.technicalMessageId}}</td>
              <td class="cdk-visually-hidden mrid">{{parentData.mrid}}</td>
              <td>
                <ng-container *ngIf="firstDataItem?.connectionEan">
                  <span (click)="goToConnection(firstDataItem?.connectionEan)" class="clickable">
                    {{firstDataItem?.connectionEan}}
                  </span>
                </ng-container>
              </td>
              <td>{{parentData.processedDate | date: 'MMM. yyyy'}}</td>
              <td>
                <ng-container *ngIf="isEditingAllowed">
                  <input type="text" class="input-style ng-pristine ng-invalid ng-touched"
                    (keyup.esc)="cancelChanges($event, firstDataItem)"
                    (keyup.enter)="saveChanges($event, firstDataItem)" (change)="onChangeExternalReference()"
                    maxlength="{{maxLengthExternalReference}}" formControlName="externalReference"
                    placeholder="CSH correctie nr." />
                </ng-container>
                <ng-container *ngIf="!isEditingAllowed">
                  {{parentData.externalReference}}
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="isExternalReferenceFilled()">
                  <mat-icon class="icon-column" matTooltip="CSH Correctie is correct gevuld" matTooltipShowDelay="1000"
                    matTooltipPosition="above">check_circle</mat-icon>
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="isEditingAllowed">
                  <select class="dropdown-select" formControlName="isCorrected"
                    (keydown.esc)="cancelChanges($event, firstDataItem)"
                    (keydown.enter)="saveChanges($event, firstDataItem)">
                    <option value="false">Nee</option>
                    <option value="true">Ja</option>
                  </select>
                </ng-container>
                <ng-container *ngIf="!isEditingAllowed">
                  {{parentData.isCorrected ? 'Ja' : 'Nee'}}
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="isEditingAllowed && isDataChanged(firstDataItem)">
                  <mat-icon iconPositionEnd class="maticon-button-table button-right"
                    (click)="saveChanges($event, firstDataItem)" matTooltip="Sla correctie data op"
                    matTooltipShowDelay="1000" matTooltipPosition="above">
                    <span>save</span>
                  </mat-icon>
                  <mat-icon iconPositionEnd class="maticon-button-table button-right"
                    (click)="cancelChanges($event, firstDataItem)" matTooltip="Annuleer wijzigingen"
                    matTooltipShowDelay="1000" matTooltipPosition="above">
                    <span>cancel</span>
                  </mat-icon>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!isLoading; else loading"></ng-container>

  <ng-container *ngIf="data">
    <div class="row">
      <ng-container *ngIf="data && dataCountIsLargerThenMinimumPageSize">
        <div class="col-sm-12 page-size-dropdown">
          <!--PageSizeSelection-->
          <label class="page-size-dropdown-label">Aantal per pagina: &nbsp;
            <select #size (change)="onPageSizeChange(size.value)" [(ngModel)]='itemsPerPage'>
              <option *ngFor="let size of pageSizes">
                {{ size }}
              </option>
            </select>
          </label>
        </div>
      </ng-container>
    </div>

    <div class="row">
      <ng-container *ngIf="data && data.totalCount > 0;else nodata">
        <div class="col-sm-12">
          <!--Data-->
          <table class="table-responsive table-front table-data-right">
            <thead>
              <th scope="col">Verbruiksmaand</th>
              <th scope="col">Prijs</th>
              <th scope="col">Json</th>
            </thead>
            <tbody>
              <ng-container *ngFor="let dataItem of data.data | paginate: paginateConfig; let i = index">
                <tr>
                  <td>{{dataItem.consumptionEndDateTime | date: 'MMM. yyyy'}}</td>
                  <td class="Price">
                    <ng-container *ngIf="dataItem.calculatedPrice">
                      <span>
                        € {{dataItem.calculatedPrice | number : '1.2-2'}}
                      </span>
                    </ng-container>
                  </td>
                  <td><default-button icon='download' text='Download Json' (click)="downloadJson(dataItem)"
                      matTooltip="Download de de volume en verrekendetails van de EAN in dit record van de VVV"
                      matTooltipShowDelay="1000" matTooltipPosition="right" />
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <!--Pagination-->
          <div class="d-flex justify-content-center">
            <pagination-controls id="{{paginateConfig.id}}" (pageChange)="goToPage($event)" previousLabel="Vorige"
              nextLabel="Volgende" class="pagination" directionlinks="true" autoHide="true" responsive="true">
            </pagination-controls>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #nodata>
  <div class="row">
    <div class="col-sm-12">
      <table>
        <thead>
          <tr class="row-nodata">
            <td colspan="12" class="nodata">
              <mat-icon class="warning-icon">report</mat-icon>
              <div class="spacer">Geen verbruik- en verreken data.</div>
            </td>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div style="width: 100%; height: auto; text-align: center">
    <loading-component></loading-component>
  </div>
</ng-template>