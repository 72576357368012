<div *ngIf="connectionError" class="overlay">
  <div class="alert alert-danger" role="alert">
    <h4 class="alert-heading">Verbindingsproblemen</h4>
    <p>
      Helaas kunnen we geen verbinding krijgen met de Server. Neem contact op
      met ICT
    </p>
  </div>
</div>

<!-- Legacy Error handling using ErrorService.ts replace with throw Error or NoticationService (if non breaking issue) -->
<ng-container *ngIf="errorService.getRecentErrors().length > 0">
  <div class="errormessage" [ngClass]="{ errormovement: 'errors' }">
    <ng-container *ngFor="let error of errorService.getRecentErrors(); let i = index">
      <h4 class="errortitle">Fout</h4>
      <p class="errortext">{{ error }}</p>
    </ng-container>
  </div>
</ng-container>

<div id="sidenav">
  <!-- Logo -->
  <div class="sidenav-tenant" *ngIf="selectedTenant">

    <mat-form-field class="dropdown-ff">
      <mat-select class="dropdown-select" panelClass="panel-class" [formControl]="selectedtenants"
        (selectionChange)="changeTenant($event)" multiple required>

        <!-- new -->
        <mat-select-trigger>
          <div class="sidenav-tenant" *ngIf="selectedTenant">
            <div class="logo color-ns" *ngIf="selectedTenant.toString() === 'NieuweStroom'">
              <span *ngIf="showNavtext" class="branding"><img class="logo-ns"
                  src="/assets/img/NieuweStroomLogo-Wit.png"></span>
            </div>
            <div class="logo color-ee" *ngIf="selectedTenant.toString() === 'EasyEnergy'">
              <span *ngIf="showNavtext" class="branding"><img class="logo-ee"
                  src="/assets/img/EasyEnergyLogo-Wit.png"></span>
            </div>
            <div class="logo color-default" *ngIf="selectedTenant.toString() === 'NieuweStroom,EasyEnergy'">
              <span *ngIf="showNavtext" class="branding"><img class="logo-ns"
                  src="/assets/img/EasyEnergyNieuweStroomLogo-Wit.png"></span>
            </div>
          </div>
        </mat-select-trigger>
        <mat-option *ngFor="let tenant of allTenantsLists" [value]="tenant">
          {{ tenant }}
        </mat-option>
      </mat-select>
      <div class="logo-none" *ngIf="selectedTenant.toString() === ''">
        <span *ngIf="showNavtext" class="branding">geen tenant</span>
      </div>
    </mat-form-field>
  </div>

  <!-- Side Navigation Links -->
  <div class="sidenav-links">
    <a *ngFor="let section of sections" [routerLink]="section.path"><span class="symbol">
        <mat-icon>{{section.icon}}</mat-icon>
      </span><span class="nav-text" *ngIf="showNavtext">{{section.name}}</span></a>
  </div>

  <!-- Minimize navbar option -->
  <div class="sidenav-links">
    <a (click)="minimizeSideNav()" class="hide-menu"><span class="symbol" *ngIf="showNavtext">
        <mat-icon>chevron_left</mat-icon>
      </span>
      <span class="symbol" *ngIf="!showNavtext">
        <mat-icon>chevron_right</mat-icon>
      </span><span class="nav-hide-show" *ngIf="showNavtext">menu inklappen</span></a>
  </div>
</div>

<div id="main">
  <!-- Navbar-top -->
  <div class="navbar-top" style="display: flex; justify-content: space-between; align-items: row; align-items: center;">
    <div>
      <span *ngIf="!productionEnvironment" class="environment-label">Staging</span>
    </div>

    <div (click)="refresh()" class="refresh-warning" *ngIf="suggestRefresh">
      <div class="refresh-button">
        <mat-icon class="icon-refresh">refresh</mat-icon>
      </div>
      <div class="refresh">
        Ververs om op de huidige pagina tenant te wijzigen.
      </div>
    </div>

    <div>
      <mat-icon class="notify-button" *ngIf="errorService.getAllErrors().length > 0"
        (click)="toggleNotificationCenter()"><span class="notify-icon">notifications</span></mat-icon>
    </div>

  </div>

  <!-- notification center (errors) -->
  <ng-container *ngIf="showNotificationCenter">
    <app-notification-center (closeNotificationCenter)="closeNotificationCenter()"
      [errors]="errorService.getAllErrors()">
    </app-notification-center>
  </ng-container>

  <!-- Content -->
  <div>
    <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
    <router-outlet *ngIf="!isIframe && requiredServicesInitialized"></router-outlet>
  </div>

  <!-- Login options, for dev use  -->
  <div style="display: none">
    <button mat-raised-button [matMenuTriggerFor]="loginMenu" *ngIf="!loginDisplay">
      Login
    </button>
    <mat-menu #loginMenu="matMenu">
      <button mat-menu-item (click)="loginRedirect()">
        Login using Redirect
      </button>
      <button mat-menu-item (click)="loginPopup()">Login using Popup</button>
    </mat-menu>

    <button mat-raised-button [matMenuTriggerFor]="logoutMenu" *ngIf="loginDisplay">
      Logout
    </button>
    <mat-menu #logoutMenu="matMenu">
      <button mat-menu-item (click)="logout()">Logout using Redirect</button>
      <button mat-menu-item (click)="logout(true)">Logout using Popup</button>
    </mat-menu>
  </div>
</div>