interface IUrlPerEnvironment {
  localUrl: string;
  stagingUrl: string;
  productionUrl: string;
}

interface IApiConfig extends IUrlPerEnvironment {
  defaultAuthScope: string;
}

interface IApiConfigWithLocalAndStagingAuth extends IApiConfig {
  localAuthScope: string;
  stagingAuthScope: string;
}

export const redirectUrls = {
  localUrl: 'http://localhost:4200/login',
  stagingUrl: 'https://operations-stage.nieuwestroom.nl/login',
  productionUrl: 'https://operations.nieuwestroom.nl/login',
} as IUrlPerEnvironment;

export const operationsApi = {
  localUrl: 'https://localhost:44323',
  stagingUrl: 'https://api-stage.nieuwestroom.nl',
  productionUrl: 'https://api.nieuwestroom.nl',
  defaultAuthScope: 'https://nieuwestroomapiservice-staging.azurewebsites.net/user_impersonation',
} as IApiConfig;

export const onboardingApi = {
  localUrl: 'https://localhost:56950',
  stagingUrl: 'https://minimalapi-stage.nieuwestroom.nl/onboarding',
  productionUrl: 'https://minimalapi.nieuwestroom.nl/onboarding',
  defaultAuthScope: 'api://minimalapi/.default',
} as IApiConfig;

export const onboardingWelcomeLettersApi = {
  localUrl: 'https://localhost:62079',
  stagingUrl: 'https://minimalapi-stage.nieuwestroom.nl/onboarding/welcomeletters',
  productionUrl: 'https://minimalapi.nieuwestroom.nl/onboarding/welcomeletters',
  defaultAuthScope: 'api://minimalapi/.default',
} as IApiConfig;

export const supplyingApi = {
  localUrl: 'https://localhost:53107',
  stagingUrl: 'https://minimalapi-stage.nieuwestroom.nl/supplying',
  productionUrl: 'https://minimalapi.nieuwestroom.nl/supplying',
  defaultAuthScope: 'api://minimalapi/.default',
} as IApiConfig;

export const tradingApi = {
  localUrl: 'https://localhost:52462',
  stagingUrl: 'https://minimalapi-stage.nieuwestroom.nl/trading',
  productionUrl: 'https://minimalapi.nieuwestroom.nl/trading',
  defaultAuthScope: 'api://minimalapi/.default',
} as IApiConfig;

export const reconciliationApi = {
  localUrl: 'https://localhost:7112/api',
  stagingUrl: 'https://reconciliation-stage.nieuwestroom.nl/api',
  productionUrl: 'https://reconciliation.nieuwestroom.nl/api',
  defaultAuthScope: 'api://reconciliation.dashboard/dashboard',
  localAuthScope: 'api://reconciliation-dev.dashboard/dashboard',
  stagingAuthScope: 'api://reconciliation-stage.dashboard/dashboard',
} as IApiConfigWithLocalAndStagingAuth;

export const tradingForecastUi = {
  localUrl: 'https://localhost:60747',
  stagingUrl: 'https://forecast-acc.nieuwestroom.nl/',
  productionUrl: 'https://forecast.nieuwestroom.nl/',
} as IUrlPerEnvironment;

export const tradingDealsUi = {
  localUrl: 'https://localhost:60747',
  stagingUrl: 'https://deals-acc.nieuwestroom.nl/',
  productionUrl: 'https://deals.nieuwestroom.nl/',
} as IUrlPerEnvironment;

export const protectedResourceMap = new Map([
  ['https://graph.microsoft-ppe.com/v1.0/me', ['user.read']],

  [operationsApi.localUrl, [operationsApi.defaultAuthScope]],
  [operationsApi.stagingUrl, [operationsApi.defaultAuthScope]],
  [operationsApi.productionUrl, [operationsApi.defaultAuthScope]],

  [onboardingApi.localUrl, [onboardingApi.defaultAuthScope]],
  [onboardingApi.stagingUrl, [onboardingApi.defaultAuthScope]],
  [onboardingApi.productionUrl, [onboardingApi.defaultAuthScope]],

  [onboardingWelcomeLettersApi.localUrl, [onboardingWelcomeLettersApi.defaultAuthScope]],
  [onboardingWelcomeLettersApi.stagingUrl, [onboardingWelcomeLettersApi.defaultAuthScope]],
  [onboardingWelcomeLettersApi.productionUrl, [onboardingWelcomeLettersApi.defaultAuthScope]],

  [supplyingApi.localUrl, [supplyingApi.defaultAuthScope]],
  [supplyingApi.stagingUrl, [supplyingApi.defaultAuthScope]],
  [supplyingApi.productionUrl, [supplyingApi.defaultAuthScope]],

  [tradingApi.localUrl, [tradingApi.defaultAuthScope]],
  [tradingApi.stagingUrl, [tradingApi.defaultAuthScope]],
  [tradingApi.productionUrl, [tradingApi.defaultAuthScope]],

  [reconciliationApi.localUrl, [reconciliationApi.localAuthScope]],
  [reconciliationApi.stagingUrl, [reconciliationApi.stagingAuthScope]],
  [reconciliationApi.productionUrl, [reconciliationApi.defaultAuthScope]],
]);

const isLocal = localStorage['target-enviroment'] === 'local';
const isProduction = window.location.href.startsWith('https://operations.nieuwestroom.nl');
const isStaging = window.location.href.startsWith('https://operations-stage.nieuwestroom.nl');

export const environment = {
  production: isProduction,

  msal: {
    clientId: '121623cd-fdc8-4272-9206-8e07bf6b1742',
    authority: 'https://login.microsoftonline.com/b65f2e69-e0ed-4835-896a-2e51e05f5da2',
    protectedResourceMap,
    scopes: ['user.read'],
  },

  redirectUrl: isProduction ? redirectUrls.productionUrl : isStaging ? redirectUrls.stagingUrl : redirectUrls.localUrl,

  getOperationsApiUrl: () =>
    isProduction ? operationsApi.productionUrl : localStorage['target-enviroment'] === 'local' ? operationsApi.localUrl : operationsApi.stagingUrl,

  getOnboardingApiUrl: () =>
    isProduction ? onboardingApi.productionUrl : localStorage['target-enviroment'] === 'local' ? onboardingApi.localUrl : onboardingApi.stagingUrl,

  getOnboardingWelcomeLettersApiUrl: () =>
    isProduction
      ? onboardingWelcomeLettersApi.productionUrl
      : localStorage['target-enviroment'] === 'local'
      ? onboardingWelcomeLettersApi.localUrl
      : onboardingWelcomeLettersApi.stagingUrl,

  getSupplyingApiUrl: () =>
    isProduction ? supplyingApi.productionUrl : localStorage['target-enviroment'] === 'local' ? supplyingApi.localUrl : supplyingApi.stagingUrl,

  getTradingApiUrl: () =>
    isProduction ? tradingApi.productionUrl : localStorage['target-enviroment'] === 'local' ? tradingApi.localUrl : tradingApi.stagingUrl,

  getReconciliationApiUrl: () =>
    isProduction ? reconciliationApi.productionUrl : isLocal ? reconciliationApi.localUrl : reconciliationApi.stagingUrl,

  getTradingForecastUiUrl: () =>
    isProduction
      ? tradingForecastUi.productionUrl
      : localStorage['target-enviroment'] === 'local'
      ? tradingForecastUi.localUrl
      : tradingForecastUi.stagingUrl,

  getTradingDealsUiUrl: () =>
    isProduction ? tradingDealsUi.productionUrl : localStorage['target-enviroment'] === 'local' ? tradingDealsUi.localUrl : tradingDealsUi.stagingUrl,
};
