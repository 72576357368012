<div class="col-sm-12">
  <div class="row date-search" *ngIf="false"> <!-- TODO: DW: hidden for now -->
    <div class="col-sm-4 date-data justify-content-center">
      <mat-form-field class="spacer">
        <div>Gecorrigeerd</div>
        <mat-select [(ngModel)]="isCorrectedFilter" (selectionChange)="changeSelectionIsCorrectedFilter($event)">
          <mat-option [value]="false"> Nee </mat-option>
          <mat-option [value]="true"> Ja </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-sm-8">
      <!--Search Bar-->
      <div class="search-data">
        <search-comp [searchString]="searchString" placeholder="Zoek op correlatie"
          (searchEvent)="searchEventlistener($event)" matTooltipClass="tooltip-search">
        </search-comp>
      </div>
      <!--End Search Bar-->
    </div>
  </div>

  <ng-container *ngIf="!isLoading; else loading"></ng-container>

  <ng-container *ngIf="data">
    <div class="row">
      <ng-container *ngIf="data && dataCountIsLargerThenMinimumPageSize">
        <div class="col-sm-12 page-size-dropdown">
          <!--PageSizeSelection-->
          <label class="page-size-dropdown-label">Aantal per pagina: &nbsp;
            <select #size (change)="onPageSizeChange(size.value)" [(ngModel)]='itemsPerPage'>
              <option *ngFor="let size of pageSizes">
                {{ size }}
              </option>
            </select>
          </label>
        </div>
      </ng-container>
    </div>

    <div class="row">
      <ng-container *ngIf="data && data.totalCount > 0;else nodata">
        <div class="col-sm-12">
          <!--Data-->
          <table class="table-responsive table-front table-data-right" [formGroup]="formDataGroup">
            <thead>
              <th scope="col" class="cdk-visually-hidden">Allocation Progress Id</th>
              <th scope="col" class="cdk-visually-hidden">Correlatie Id</th>
              <th scope="col" class="cdk-visually-hidden">Bericht Id</th>
              <th scope="col" class="cdk-visually-hidden">Kenmerk</th>
              <th scope="col" class="col-2">Netbeheerder</th>
              <th scope="col" class="col-1">Indiener</th>
              <th scope="col" class="col-1">Proces maand</th>
              <th scope="col" class="col-1">Product</th>
              <th scope="col" class="col-1">Totaal prijs</th>
              <th scope="col" class="col-2">Ontvangen op</th>
              <th scope="col" class="col-2">CSH Factuur</th>
              <th scope="col" class="col-1">
                <ng-container *ngIf="isEditingAllowed && isEditingAnyExternalReference">
                  <mat-icon iconPositionEnd class="maticon-button-table" (click)="saveAllExternalReferences()"
                    matTooltip="Sla alle wijzigingen op" matTooltipShowDelay="1000" matTooltipPosition="above">
                    <span>save</span>
                  </mat-icon>
                  <mat-icon iconPositionEnd class="maticon-button-table" (click)="cancelAllExternalReferences()"
                    matTooltip="Annuleer alle wijzigingen" matTooltipShowDelay="1000" matTooltipPosition="above">
                    <span>cancel</span>
                  </mat-icon>
                </ng-container>
              </th>
              <th scope="col" class="col-2">Gecorrigeerd</th>
              <th scope="col"></th>
            </thead>
            <tbody>
              <ng-container formArrayName="rows"
                *ngFor="let dataItem of data.data | paginate: paginateConfig; let i = index">
                <tr [formGroupName]="i">
                  <td class="cdk-visually-hidden allocationProgressId">{{dataItem.id}}</td>
                  <td class="cdk-visually-hidden senderCorrelationId">{{dataItem.senderCorrelationID}}</td>
                  <td class="cdk-visually-hidden technicalMessageId">{{dataItem.technicalMessageId}}</td>
                  <td class="cdk-visually-hidden mrid">{{dataItem.mrid}}</td>
                  <td>{{dataItem.gridOperatorName}}</td>
                  <td>{{dataItem.receiverName}}</td>
                  <td>{{dataItem.processedDate | date: 'MMM. yyyy'}}</td>
                  <td>{{dataItem.productTypeName}}</td>
                  <td class="Price">
                    <ng-container *ngIf="dataItem.totalPrice">
                      <span>
                        € {{dataItem.totalPrice | number : '1.2-2'}}
                      </span>
                    </ng-container>
                  </td>
                  <td>{{dataItem.receivedOn | date: 'dd-MM-yyyy HH:mm uur'}}</td>
                  <td>
                    <ng-container *ngIf="!isEditingExternalReference[i]">
                      <span (dblclick)="editExternalReference(i, dataItem);"
                        matTooltip="{{(dataItem.externalReference && dataItem.externalReference.length > 16) ? dataItem.externalReference : ''}}"
                        matTooltipShowDelay="1000" matTooltipPosition="above">
                        {{ fillWithSpaces(dataItem.externalReference, 16) | truncate:16:'...'}}
                      </span>
                    </ng-container>
                    <ng-container *ngIf="isEditingAllowed && isEditingExternalReference[i]">
                      <input type="text" class="input-style ng-pristine ng-invalid ng-touched"
                        (keyup.esc)="cancelEditExternalReference(i, dataItem)"
                        (keyup.enter)="saveExternalReference(i, dataItem)" maxlength="{{maxLengthExternalReference}}"
                        formControlName="externalReference" placeholder="CSH factuur nr." />
                    </ng-container>
                  </td>
                  <td>
                    <ng-container *ngIf="isEditingAllowed">
                      <ng-container *ngIf="!isEditingExternalReference[i]">
                        <mat-icon iconPositionEnd class="maticon-button-table"
                          (click)="editExternalReference(i, dataItem);" matTooltip="Pas de CSH factuur aan"
                          matTooltipShowDelay="1000" matTooltipPosition="right">
                          <span>edit</span>
                        </mat-icon>
                      </ng-container>
                      <ng-container *ngIf="isEditingExternalReference[i]">
                        <mat-icon iconPositionEnd class="maticon-button-table"
                          (click)="saveExternalReference(i, dataItem);" matTooltip="Sla de CSH factuur op"
                          matTooltipShowDelay="1000" matTooltipPosition="above">
                          <span>save</span>
                        </mat-icon>
                        <mat-icon iconPositionEnd class="maticon-button-table"
                          (click)="cancelEditExternalReference(i, dataItem)" matTooltip="Annuleer wijzigingen"
                          matTooltipShowDelay="1000" matTooltipPosition="right">
                          <span>cancel</span>
                        </mat-icon>
                      </ng-container></ng-container>
                  </td>
                  <td>{{dataItem.correctedOn | date: 'dd-MM-yyyy HH:mm uur'}}</td>
                  <td><default-button (click)="openDetail(dataItem.id)" text="Details"
                      matTooltip="Ga naar de VVV details" matTooltipShowDelay="1000" matTooltipPosition="left" /></td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <!--Pagination-->
          <div class="d-flex justify-content-center">
            <pagination-controls id="{{paginateConfig.id}}" (pageChange)="goToPage($event)" previousLabel="Vorige"
              nextLabel="Volgende" class="pagination" directionlinks="true" autoHide="true" responsive="true">
            </pagination-controls>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #nodata>
  <div class="row">
    <div class="col-sm-12">
      <table>
        <thead>
          <tr class="row-nodata">
            <td colspan="12" class="nodata">
              <mat-icon class="warning-icon">report</mat-icon>
              <div class="spacer">Geen VVV data.</div>
            </td>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div style="width: 100%; height: auto; text-align: center">
    <loading-component></loading-component>
  </div>
</ng-template>