import { catchError, filter, of, tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { environment } from '@environment';

import {
  sectionsGeneric,
  sectionsOnboarding,
  sectionsReconciliations,
  sectionsStaging,
  sectionsSupplying,
  sectionsTrading,
} from '../Constants/main-dashboard-sections.constants';

const domainApis = {
  trading: {
    name: 'trading',
    apis: [
      {
        name: 'Timeseries',
        url: environment.getTradingApiUrl() + '/timeseries' + '/hello',
      },
      {
        name: 'Deals',
        url: environment.getTradingApiUrl() + '/deal' + '/hello',
      },
      {
        name: 'Preliminary Purchase',
        url: environment.getTradingApiUrl() + '/preliminarypurchase' + '/hello',
      },
      {
        name: 'Allocation',
        url: environment.getTradingApiUrl() + '/allocation' + '/hello',
      },
      {
        name: 'Forecast',
        url: environment.getTradingApiUrl() + '/forecast' + '/hello',
      },
    ],
  },
  onboarding: { name: 'onboarding', apis: [] },
  reconciliation: {
    name: 'reconciliation',
    apis: [
      {
        name: 'Allocation Progresses',
        url: environment.getReconciliationApiUrl() + '/allocation-progresses' + '/is-alive',
      },
    ],
  },
  supplying: { name: 'supplying', apis: [] },
};

@Injectable({
  providedIn: 'root',
})
export class MsalBasedAuthorizationService {
  constructor(private broadcastService: MsalBroadcastService, private http: HttpClient) {}

  get authorizationListener$(): any {
    return this.broadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
      tap((result) => this.storeMinimalApiToken(result.payload)),
      tap((result) => this.storeOperationsApiToken(result.payload)),
      tap((result) => this.storeReconciliationApiToken(result.payload)),
    );
  }

  initializeMinimalApis = () =>
    Object.values(domainApis).map(
      (d: any) =>
        d.apis.length &&
        this.http
          .get(d.apis[0]?.url)
          .pipe(catchError((e) => of(null)))
          .subscribe(),
    );

  isTradingAuthorized = () => this.isAuthorized(domainApis.trading.name, this.getRolesFromMinimalApi());

  isOnboardingAuthorized = () => this.isAuthorized(domainApis.onboarding.name, this.getRolesFromMinimalApi());

  isReconciliationAuthorized = () => this.isAuthorized(domainApis.reconciliation.name, this.getRolesFromReconciliationApi());

  isAuthorized = (moduleName, roles) => roles && roles.some((r) => r?.toLowerCase()?.includes(moduleName.toLowerCase()));

  hasReconciliationRole = (role) => this.hasRole(role, this.getRolesFromReconciliationApi());

  hasRole = (role, roles) => roles && roles.some((r) => r?.toLowerCase() === role.toLowerCase());

  getRolesFromMinimalApi = () => (this.getMinimalApiMsalToken() ? decodedJwt(this.getMinimalApiMsalToken())?.roles : []);

  getRolesFromReconciliationApi = () => (this.getReconciliationApiMsalToken() ? decodedJwt(this.getReconciliationApiMsalToken())?.roles : []);

  getOperationsApiMsalToken = () => localStorage.getItem('msal_token');

  getMinimalApiMsalToken = () => localStorage.getItem('msal_token_minimalapi');

  getReconciliationApiMsalToken = () => localStorage.getItem('msal_token_reconciliation');

  // DEBUG SHOW ALL SECTIONS
  // allowedSections = () => [...sectionsGeneric, ...sectionsSupplying, ...sectionsOnboarding, ...sectionsTrading, ...sectionsReconciliations, ...sectionsStaging];

  allowedSections = () => [
    ...sectionsGeneric,
    ...sectionsSupplying,
    ...(this.isOnboardingAuthorized() ? sectionsOnboarding : []),
    ...(this.isTradingAuthorized() ? sectionsTrading : []),
    ...(this.isReconciliationAuthorized() ? sectionsReconciliations : []),
    ...(!environment.production ? sectionsStaging : []),
  ];

  private storeMinimalApiToken(payload) {
    if (!payload) return;
    // scopes:['api://minimalapi/user_impersonation', 'api://minimalapi/.default']
    const isMinimalApiToken = payload.scopes.some((i) => i.includes('minimalapi'));
    if (isMinimalApiToken) {
      localStorage.setItem('msal_token_minimalapi', payload.accessToken);
    }
  }

  private storeOperationsApiToken(payload) {
    if (!payload) return;
    const isOperationApiToken = payload.scopes.some((i) => i.includes('nieuwestroomapiservice'));
    // scopes:['https://nieuwestroomapiservice-staging.azurewebsites.net/user_impersonation']
    if (isOperationApiToken) {
      localStorage.setItem('msal_token', payload.accessToken);
    }
  }

  private storeReconciliationApiToken(payload) {
    if (!payload) return;
    const isReconciliationApiToken = payload.scopes.some((i) => i.includes('reconciliation'));
    // scopes:['api://reconciliation[-dev][-stage].dashboard.api/dashboard']
    if (isReconciliationApiToken) {
      localStorage.setItem('msal_token_reconciliation', payload.accessToken);
    }
  }
}

const decodedJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );
  return JSON.parse(jsonPayload);
};
