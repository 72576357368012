<div class="col-sm-12">
  <ng-container *ngIf="false"> <!-- TODO: DW: hidden for now -->
    <div class="row date-search">
      <div class="col-sm-8">
        <!--Search Bar-->
        <div class="search-data">
          <search-comp [searchString]="searchString" placeholder="Zoek op EAN"
            (searchEvent)="searchEventlistener($event)" matTooltipClass="tooltip-search">
          </search-comp>
        </div>
        <!--End Search Bar-->
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="parentData">
    <div class="row">
      <div class="col-sm-12">
        <!--Header-->
        <table class="table-responsive table-front table-data-right">
          <thead>
            <th scope="col" class="cdk-visually-hidden">Allocation Progress Id</th>
            <th scope="col" class="cdk-visually-hidden">Correlatie Id</th>
            <th scope="col" class="cdk-visually-hidden">Bericht Id</th>
            <th scope="col" class="cdk-visually-hidden">Kenmerk</th>
            <th scope="col" class="col-3">Netbeheerder</th>
            <th scope="col" class="cdk-visually-hidden">Indiener (EAN)</th>
            <th scope="col" class="cdk-visually-hidden">Indiener</th>
            <th scope="col" class="col-2">Proces maand</th>
            <th scope="col" class="col-3">Product</th>
            <th scope="col" class="col-4">Ontvangen op</th>
            <th scope="col" class="col-4">Gecorrigeerd</th>
          </thead>
          <tbody>
            <tr>
              <td class="cdk-visually-hidden allocationProgressId">{{parentData.id}}</td>
              <td class="cdk-visually-hidden senderCorrelationID">{{parentData.senderCorrelationID}}</td>
              <td class="cdk-visually-hidden technicalMessageId">{{parentData.technicalMessageId}}</td>
              <td class="cdk-visually-hidden mrid">{{parentData.mrid}}</td>
              <td>{{parentData.gridOperatorName}}</td>
              <td class="cdk-visually-hidden receiverEan">{{parentData.receiverEan}}</td>
              <td class="cdk-visually-hidden receiverName">{{parentData.receiverName}}</td>
              <td>{{parentData.processedDate | date: 'MMM. yyyy'}}</td>
              <td>{{parentData.productTypeName}}</td>
              <td>{{parentData.receivedOn | date: 'dd-MM-yyyy HH:mm uur'}}</td>
              <td>{{parentData.isCorrected ? 'Ja' : 'Nee'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!isLoading; else loading"></ng-container>

  <ng-container *ngIf="data">
    <div class="row">
      <ng-container *ngIf="data && dataCountIsLargerThenMinimumPageSize">
        <div class="col-sm-12 page-size-dropdown">
          <!--PageSizeSelection-->
          <label class="page-size-dropdown-label">Aantal per pagina: &nbsp;
            <select #size (change)="onPageSizeChange(size.value)" [(ngModel)]='itemsPerPage'>
              <option *ngFor="let size of pageSizes">
                {{ size }}
              </option>
            </select>
          </label>
        </div>
      </ng-container>
    </div>

    <div class="row">
      <ng-container *ngIf="data && data.totalCount > 0;else nodata">
        <div class="col-sm-12">
          <!--Data-->
          <table class="table-responsive table-front table-data-right">
            <thead>
              <th scope="col" class="col-3">EAN</th>
              <th scope="col" class="col-2">Verbruiksmaand</th>
              <th scope="col" class="col-2">Prijs</th>
              <th scope="col" class="col-4">CSH Correctie</th>
              <th scope="col" class="col-2">Gecorrigeerd</th>
              <th scope="col"></th>
            </thead>
            <tbody>
              <ng-container *ngFor="let dataItem of data.data | paginate: paginateConfig; let i = index">
                <tr>
                  <td>
                    <ng-container *ngIf="dataItem.connectionEan">
                      <span (click)="goToConnection(dataItem.connectionEan)"
                        class="clickable">{{dataItem.connectionEan}}</span>
                    </ng-container>
                  </td>
                  <td>{{dataItem.consumptionEndDateTime | date: 'MMM. yyyy'}}</td>
                  <td class="Price">
                    <ng-container *ngIf="dataItem.calculatedPrice">
                      <span>
                        € {{dataItem.calculatedPrice | number : '1.2-2'}}
                      </span>
                    </ng-container>
                  </td>
                  <td>{{dataItem.externalReference}}</td>
                  <td>{{dataItem.isCorrected === true ? "Ja" : "Nee"}}</td>
                  <td><default-button (click)="openVolume(dataItem.connectionEan)" text="Volumes/verrekeningen"
                      matTooltip="Ga naar de volume en verrekendetails van de EAN in dit record van de VVV"
                      matTooltipShowDelay="1000" matTooltipPosition="left" /></td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <!--Pagination-->
          <div class="d-flex justify-content-center">
            <pagination-controls id="{{paginateConfig.id}}" (pageChange)="goToPage($event)" previousLabel="Vorige"
              nextLabel="Volgende" class="pagination" directionlinks="true" autoHide="true" responsive="true">
            </pagination-controls>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #nodata>
  <div class="row">
    <div class="col-sm-12">
      <table>
        <thead>
          <tr class="row-nodata">
            <td colspan="12" class="nodata">
              <mat-icon class="warning-icon">report</mat-icon>
              <div class="spacer">Geen VVV details.</div>
            </td>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div style="width: 100%; height: auto; text-align: center">
    <loading-component></loading-component>
  </div>
</ng-template>