import moment from 'moment-timezone';
import { MOMENT_DATETIME_API_FORMAT } from 'src/app/constants';
import { ParameterValueR } from 'src/app/modules/operations-api';
import { ParameterValueService } from 'src/app/services/parameter-value.service';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-parameter-value',
  templateUrl: './edit-parameter-value.component.html',
  styleUrls: ['./edit-parameter-value.component.scss'],
})
export class EditParameterValueComponent implements OnInit {
  @Input() parameter; //ParameterValue;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() completed: EventEmitter<boolean> = new EventEmitter<boolean>();

  createNewParameter = false;

  setAFutureFrom = false;
  showTimeForm = false;

  parameterValueForm: UntypedFormGroup;

  constructor(
    // initialize form
    private parameterValueService: ParameterValueService,
  ) {
    this.parameterValueForm = new UntypedFormGroup({
      value: new UntypedFormControl('', [Validators.required]),
      from: new UntypedFormControl('', [Validators.required]),
      time: new UntypedFormControl(''),
    });
  }

  ngOnInit(): void {
    this.createNewParameter = this.parameter === undefined ? true : false;
    console.log('create: ' + this.createNewParameter);
    this.parameterValueForm.patchValue({
      value: JSON.stringify(this.parameter.Value, null, 2), // Pretty JSON -> https://stackoverflow.com/questions/4810841/pretty-print-json-using-javascript
    });
  }

  setFutureFrom() {
    this.setAFutureFrom = !this.setAFutureFrom;
  }

  save() {
    const validJson = JSON.parse(this.parameterValueForm.get('value').value);

    if (!validJson) {
      throw new Error('Geen valide input waarde, controleer op fouten.');
    }

    const parameterToUpdate: ParameterValueR = {
      Value: JSON.parse(this.parameterValueForm.get('value').value),
      ParameterValueName: this.parameter.ParameterValueName,
      Tenant: this.parameter.Tenant,
      From: moment().format(MOMENT_DATETIME_API_FORMAT),
    };

    console.log(parameterToUpdate);
    this.parameterValueService.updateParameterValue(parameterToUpdate);
    this.completed.emit(true);
  }

  deleteParameter() {
    this.parameterValueService.deleteParameterValue(this.parameter);
    this.completed.emit(true);
  }

  setCurrentDateAndTime() {
    if (!this.setAFutureFrom) {
      return moment();
    }
    let dateTime = this.parameterValueForm.get('From').value;
    if (!this.showTimeForm) {
      return dateTime;
    }
    const time = this.parameterValueForm.get('time').value.split(':');
    dateTime.set({ hour: time[0], minute: time[1] });
    return dateTime;
  }

  addTime() {
    this.parameterValueForm.patchValue({
      time: moment().format('HH:mm'),
    });
    this.showTimeForm = !this.showTimeForm;
  }

  cancelEdit() {
    this.cancel.emit(this.parameter);
  }
}
